import React from 'react'
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

/**
 * UI Overwrite
 * $rhap_theme-color: #868686 !default;   // Color of all buttons and volume/progress indicators
 * $rhap_background-color: #fff !default; // Color of the player background
 * $rhap_bar-color: #e4e4e4 !default;     // Color of volume and progress bar
 * $rhap_time-color: #333 !default;       // Font color of current time and duration
 * $rhap_font-family: inherit !default;   // Font family of current time and duration
 */

const music = (props) => {
    let { src, onPlay } = props
    // console.log("src", src);
    return (
        <AudioPlayer
            src={src}
            onPlay={e => {}}
            customAdditionalControls={[]}
            autoPlayAfterSrcChange={true}
            autoPlay={true}
            loop={true}
        // other props here
        />
    )
}

export default music
