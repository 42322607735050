/**
 * ===========================================================
 *                  All Type Create at Here
 * ===========================================================
 */

/**
 * ===========================================================
 *                  Home
 * ===========================================================
 */
export const HOME_SET_LOADER = "HOME_SET_LOADER"

/**
 * ===========================================================
 *                           ULTIMATE
 * ===========================================================
 */
export const ULTIMATE_SET_LOADER = "ULTIMATE_SET_LOADER"
export const ULTIMATE_GET_WISH = "ULTIMATE_GET_WISH"
export const ULTIMATE_GET_WISH_SUCCESS = "ULTIMATE_GET_WISH_SUCCESS"
export const ULTIMATE_POST_WISH = "ULTIMATE_POST_WISH"
export const ULTIMATE_POST_WISH_SUCCESS = "ULTIMATE_POST_WISH_SUCCESS"

// Client
export const ULTIMATE_GET_WISH_CLIENT = "ULTIMATE_GET_WISH_CLIENT"
export const ULTIMATE_GET_WISH_CLIENT_SUCCESS = "ULTIMATE_GET_WISH_CLIENT_SUCCESS"
export const ULTIMATE_POST_WISH_CLIENT = "ULTIMATE_POST_WISH_CLIENT"
export const ULTIMATE_POST_WISH_CLIENT_SUCCESS = "ULTIMATE_POST_WISH_CLIENT_SUCCESS"
export const ULTIMATE_GET_WISH_BY_ID = "ULTIMATE_GET_WISH_BY_ID"
export const ULTIMATE_GET_WISH_BY_ID_SUCCESS = "ULTIMATE_GET_WISH_BY_ID_SUCCESS"

//Acropolis
export const ACROPOLIS_REGISTER_ATTENDANCE = "ACROPOLIS_REGISTER_ATTENDANCE"
export const ACROPOLIS_REGISTER_ATTENDANCE_SUCCESS = "ACROPOLIS_REGISTER_ATTENDANCE_SUCCESS"
export const ACROPOLIS_GET_ATTENDE_LIST = "ACROPOLIS_GET_ATTENDE_LIST"
export const ACROPOLIS_GET_ATTENDE_LIST_SUCCESS = "ACROPOLIS_GET_ATTENDE_LIST_SUCCESS"
export const ACROPOLIS_HANDLE_GLOBAL_STATE = "ACROPOLIS_HANDLE_GLOBAL_STATE"
export const ACROPOLIS_HANDLE_SOURCE = "ACROPOLIS_HANDLE_SOURCE"