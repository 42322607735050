import React from "react";
import { Helmet } from "react-helmet";

const Meta = ({ metaTitle, description, keywords, image, url }) => {
    return (
        <div className="application">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{metaTitle}</title>

                {/* {/ NORMAL /} */}
                <meta name="name" content={metaTitle} />
                <meta name="description" content={description} />
                <meta name="keywords" content={keywords} />
                <meta name="image" content={image || "img/logo.png"} />

                {/* {/ GOOGLE /} */}
                <meta itemProp="name" content={metaTitle} />
                <meta itemProp="description" content={description} />
                <meta itemProp="image" content={image || "img/logo.png"} />

                {/* {/FACEBOOK/} */}
                <meta property="og:url" content={url || "https://weboosh.id/"} />
                <meta property="og:type" content={"website"} />
                <meta property="og:title" content={metaTitle} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content={image || "img/logo.png"} />

                {/* {/TWITTER} */}
                <meta name="twitter:card" content={"summary_large_image"} />
                <meta name="twitter:title" content={metaTitle} />
                <meta name="twitter:description" content={description} />
                <meta name="twitter:image" content={image || "img/logo.png"} />

                <link rel="canonical" href={url || "https://weboosh.id/"} />
            </Helmet>
        </div>
    );
};

export default Meta;