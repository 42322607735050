import React, { Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store, history } from '../../redux/store';
import { ConnectedRouter } from 'connected-react-router';
import 'bootstrap/dist/css/bootstrap.css';
import '../../asset/styles/style.scss';

import NotFound from '../../components/page/404';
import Demo from "../Demo/Index";
import Home from '../home/home.container';
import Basic from '../../components/page/basic/basic';
// import Premium from '../../components/page/premium/premium';
import Gold from '../../components/page/gold';
import Platinum from '../../components/page/platinum';

// Client
import Abeldien from '../../components/page/abeldien';
import Abeldien1 from '../../components/page/abeldien1';
import Abeldien2 from '../../components/page/abeldien2';
import Olin from '../../components/page/olin/index.js';
import Ginia from '../../components/page/ginia/index.js';
import Jonathan from '../../components/page/client/basic_jonathan';
import Elvia from '../../components/page/elvia/index.js';
import Jingga from '../../components/page/jingga/index.js';
import Leo from '../../components/page/leomira/index.js';
import Acropolis from "../../components/page/acropolis";
import AcropolisAdmin from "../../components/page/acropolis/admin";
import Dennis from '../../components/page/dennis';
import CindyOld from '../../components/page/cindy/index_old.js';

import CindySby from '../../components/page/cindy';
import CindySbyAttendence from '../../components/page/cindy/attendence.js';
import CindyJkt from '../../components/page/cindyJkt';
import CindyJktAttendence from '../../components/page/cindyJkt/attendence.js';

const App = () => {
    // console.log("public url", process.env.PUBLIC_URL);
    return (
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <Router>
                    <Fragment>
                        {/* <Header/> */}
                        <Switch>
                            <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home}/>
                            <Route exact path={`${process.env.PUBLIC_URL}/to=:guest`} component={Home}/>

                            <Route exact path={`${process.env.PUBLIC_URL}/basic`} component={Demo} />
                            <Route exact path={`${process.env.PUBLIC_URL}/premium`} component={Basic} />
                            <Route exact path={`${process.env.PUBLIC_URL}/gold`} component={Gold} />
                            <Route exact path={`${process.env.PUBLIC_URL}/platinum`} component={Platinum} />
                            <Route exact path={`${process.env.PUBLIC_URL}/platinum/to=:guest`} component={Platinum} />

                            <Route exact path={`${process.env.PUBLIC_URL}/demo`} component={Abeldien} />
                            <Route exact path={`${process.env.PUBLIC_URL}/demo/to=:guest`} component={Abeldien} />

                            <Route exact path={`${process.env.PUBLIC_URL}/demo1`} component={Abeldien1} />
                            <Route exact path={`${process.env.PUBLIC_URL}/demo1/to=:guest`} component={Abeldien1} />

                            <Route exact path={`${process.env.PUBLIC_URL}/demo2`} component={Abeldien2} />
                            <Route exact path={`${process.env.PUBLIC_URL}/demo2/to=:guest`} component={Abeldien2} />

                            <Route exact path={`${process.env.PUBLIC_URL}/wansaholin`} component={Olin} />
                            <Route exact path={`${process.env.PUBLIC_URL}/wansaholin/to=:guest`} component={Olin} />

                            <Route exact path={`${process.env.PUBLIC_URL}/bonareuginia`} component={Ginia} />
                            <Route exact path={`${process.env.PUBLIC_URL}/bonareuginia/to=:guest`} component={Ginia} />

                            <Route exact path={`${process.env.PUBLIC_URL}/jonathanmarchella`} component={Jonathan} />

                            <Route exact path={`${process.env.PUBLIC_URL}/jonatanelvia`} component={Elvia} />
                            <Route exact path={`${process.env.PUBLIC_URL}/jonatanelvia/to=:guest`} component={Elvia} />

                            <Route exact path={`${process.env.PUBLIC_URL}/dickyjingga`} component={Jingga} />
                            <Route exact path={`${process.env.PUBLIC_URL}/dickyjingga/to=:guest`} component={Jingga} />

                            <Route exact path={`${process.env.PUBLIC_URL}/leomira`} component={Leo} />
                            <Route exact path={`${process.env.PUBLIC_URL}/leomira/to=:guest`} component={Leo} />

                            <Route exact path={`${process.env.PUBLIC_URL}/acropolis`} component={Acropolis} />
                            <Route exact path={`${process.env.PUBLIC_URL}/acropolis/admin`} component={AcropolisAdmin} />

                            <Route exact path={`${process.env.PUBLIC_URL}/dennismeiviana`} component={Dennis} />
                            <Route exact path={`${process.env.PUBLIC_URL}/dennismeiviana/to=:guest`} component={Dennis} />

                            <Route exact path={`${process.env.PUBLIC_URL}/kevincindy_old`} component={CindyOld} />
                            <Route exact path={`${process.env.PUBLIC_URL}/kevincindy_old/to=:guest`} component={CindyOld} />

                            <Route exact path={`${process.env.PUBLIC_URL}/kevincindy`} component={CindySby} />
                            <Route exact path={`${process.env.PUBLIC_URL}/kevincindy/to=:guest`} component={CindySby} />
                            <Route exact path={`${process.env.PUBLIC_URL}/kevincindy/attendence`} component={CindySbyAttendence} />

                            <Route exact path={`${process.env.PUBLIC_URL}/jkt/kevincindy`} component={CindyJkt} />
                            <Route exact path={`${process.env.PUBLIC_URL}/jkt/kevincindy/to=:guest`} component={CindyJkt} />
                            <Route exact path={`${process.env.PUBLIC_URL}/jkt/kevincindy/attendence`} component={CindyJktAttendence} />

                            <Route component={NotFound} />
                        </Switch>
                    </Fragment>
                </Router>
            </ConnectedRouter>
        </Provider>
    )
}

export default App
