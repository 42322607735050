import React, { lazy, useEffect, useState, Suspense, useRef } from 'react'
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../../redux/Ultimate/action'
import 'antd/dist/antd.css';
import { Timeline } from 'antd';
import { HeartFilled } from '@ant-design/icons';

import data from '../../../data/data.json'
import AudioPlayer from '../../player/music/music'
import { Fade, Zoom } from 'react-reveal';
import HeadShake from 'react-reveal/HeadShake';
import {
    rekening,

    coklat1,
    coklatMuda1,
    coklat2,
    coklatMuda2,
    platinum1,
    platinum2,
    platinum3,
    platinum5,
    ilustrasi1,
    ilustrasi2,
    
    home,
    couple,
    gallery,
    calender,
    wish,
    quote,
    
    marryyourdaughter
} from '../../../asset'
import { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt, faMap, faPaperPlane } from '@fortawesome/free-solid-svg-icons'

import LightGallery from 'lightgallery/react';

// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

// If you want you can use SCSS instead of css
import 'lightgallery/scss/lightgallery.scss';
import 'lightgallery/scss/lg-zoom.scss';

// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

import Splash from './splash.js'
import WindowDimension from '../../windowDimension';
const Footer = lazy(() => import('../../../components/layout/Footer/ultimate/ultimate'))
const Protocol = lazy(() => import('../../../components/layout/Protocol'))

const Platinum = (props) => {
    const { Ultimate, fetchWishes, postWish} = props
    const { height, width } = WindowDimension()
    let { isLoading, wishesList} = Ultimate

    let { guest } = useParams();

    const content = useRef(null)
    const homeRef = useRef(null)
    const coupleRef = useRef(null)
    const eventRef = useRef(null)
    const galleryRef = useRef(null)
    const quoteRef = useRef(null)
    const wishRef = useRef(null)

    const [openInv, setOpenInv] = useState(false)
    const [audioSrc, setAudioSrc] = useState('')
    const [playing, setPlaying] = useState(false);
    const [name, setName] = useState("")
    const [wishes, setWishes] = useState("")
    const [openModal, setOpenModal] = useState(false)
    const [rekening1, setRekening1] = useState(88888888)

    if (openModal){
        document.body.style.overflow = 'hidden';
    }else{
        document.body.style.overflow = 'unset';
    }
    
    useEffect(() => {
        window.scrollTo(0, 0) //Auto scroll top setiap buka
        setAudioSrc(marryyourdaughter)
        setPlaying(true)
        fetchWishes()
    }, [])

    useEffect(() => {
        if (content.current) {
            setPlaying(true)
            content.current.scrollIntoView({
                behavior: 'smooth'
            });
        }
    }, [openInv])

    const onChangeRef = (ref) => {
        switch (ref) {
            case "home":
                if (homeRef.current) {
                    homeRef.current.scrollIntoView({
                        behavior: 'smooth'
                    });
                }
                break;
            case "couple":
                if (coupleRef.current) {
                    coupleRef.current.scrollIntoView({
                        behavior: 'smooth'
                    });
                }
                break;
            case "event":
                if (eventRef.current) {
                    eventRef.current.scrollIntoView({
                        behavior: 'smooth'
                    });
                }
                break;
            case "gallery":
                if (galleryRef.current) {
                    galleryRef.current.scrollIntoView({
                        behavior: 'smooth'
                    });
                }
                break;
            case "quote":
                if (quoteRef.current) {
                    quoteRef.current.scrollIntoView({
                        behavior: 'smooth'
                    });
                }
                break;
            case "wish":
                if (wishRef.current) {
                    wishRef.current.scrollIntoView({
                        behavior: 'smooth'
                    });
                }
                break;
            default:
                break;
        }
    }

    const onInit = () => {
        console.log('E-Invitation by Weboosh has been initialized');
    };

    const onSubmit = (e) => {
        e.preventDefault()
        if(name !== "" && wishes !== ""){
            let data = {
                name, wishes
            }
            postWish(data)
            setName("")
            setWishes("")
        }else{
            alert("Please Fill Your Name and Wishes First")
        }
    }

    const Popup = () => {
        
        return(
            <div className='popup'>
                <span className="close" onClick={() => setOpenModal(!openModal)}>&times;</span>
                <div className='popup_inner'>
                    <p className="txt-size-2rem font-a-b">Digital Gift</p>
                    <p className="font-m-r">Your kind blessing can be sent to this information bellow</p>
                    <div className="popup-img">
                        <img
                            className="popup-img-content"
                            src={rekening}
                            alt="Rekening"
                        />
                    </div>
                    <button className="btn-popup" onClick={() => {
                        navigator.clipboard.writeText(rekening1)
                        alert(`Copied to clipboard: ${rekening1}`)
                    }}>
                        Copy to Clipboard
                    </button>
                </div>
            </div>
        )
    }

    return (
        <div id="ultimate-page">
            <div ref={homeRef} className="container-body">
                <Splash onOpen={setOpenInv} guest={guest} />
                {
                    openInv ?
                        <Fragment>
                            <div ref={content} className="content">
                                <div ref={coupleRef} className="container-banners bg-banner">
                                    <div className="container-image-banner">
                                        <img
                                            className="image-banner"
                                            src={coklat1}
                                            loading="lazy"
                                            alt="einvitation"
                                        />
                                    </div>
                                    <div className="container-content-banner">
                                        <div className="container-icon-banner">
                                            <Zoom>
                                                <img
                                                    className="icon-banner"
                                                    src={ilustrasi1}
                                                    loading="lazy"
                                                    alt="icon"
                                                />
                                            </Zoom>
                                        </div>
                                        <Zoom>
                                            <div className="text-banner">
                                                <p className="txt-size-1rem font-m-r">{data.verse.one}</p>
                                                <p className="txt-size-075rem font-m-m">{data.verse.oneDesc}</p>
                                            </div>
                                        </Zoom>
                                        <div className="container-image2-banner">
                                            <img
                                                className="image-banner"
                                                src={coklatMuda1}
                                                loading="lazy"
                                                alt="einvitation"
                                            />
                                        </div>
                                        <div className="container-image3-banner">
                                            <Fade>
                                                <img
                                                    className="image3-banner"
                                                    src={platinum1}
                                                    loading="lazy"
                                                    alt="einvitation"
                                                />
                                            </Fade>
                                        </div>
                                    </div>
                                </div>
                                <div  className="container-bride">
                                    <div className="container-icon-bride">
                                        <Zoom>
                                            <img
                                                className="icon-bride"
                                                src={ilustrasi2}
                                                loading="lazy"
                                                alt="icon"
                                            />
                                        </Zoom>
                                    </div>
                                    <div className="title-bride">
                                        <Fade bottom>
                                            <div className="txt-size-2rem font-a-b">The Groom & Bride</div>
                                        </Fade>
                                    </div>
                                    <Zoom>
                                        <div className="container-bride-name">
                                            <div className="font-ar txt-size-2rem">{data.couple.groomName}</div>
                                            <div className="font-m-r">{data.couple.groomParents}</div>
                                        </div>
                                    </Zoom>
                                    <Fade left>
                                        <div className="container-bride-photo">
                                            <img
                                                className="bride-photo"
                                                src={platinum2}
                                                loading="lazy"
                                                alt="einvitation"
                                            />
                                        </div>
                                    </Fade>
                                    <br /><br />
                                    <Fade right>
                                        <div className="container-bride-photo">
                                            <img
                                                className="bride-photo"
                                                src={platinum3}
                                                loading="lazy"
                                                alt="einvitation"
                                            />
                                        </div>
                                    </Fade>
                                    <Zoom>
                                        <div className="container-bride-name">
                                            <div className="font-ar txt-size-2rem">{data.couple.brideName}</div>
                                            <div className="font-m-r">{data.couple.groomParents}</div>
                                        </div>
                                    </Zoom>
                                </div>
                                <br /><br />
                                <div ref={eventRef} className="container-event">
                                    <Zoom>      
                                        <p className="txt-size-2rem font-a-b">Save The Date</p>
                                        <div className="content-event">
                                            <p className="font-m-r">Holy Matrimony</p>
                                            <FontAwesomeIcon icon={faCalendarAlt}/>
                                            <p className="font-m-r">Saturday, June 5th 2021</p>
                                            <div className="line"></div>
                                            <p className="font-m-r">09 - 10.30 AM</p>

                                            <FontAwesomeIcon icon={faMap} />
                                            <p className="font-m-r">Gereja Katedral Jakarta</p>
                                            <div className="line"></div>
                                            <p className="font-m-r">Jl. Katedral No.7B, Ps. Baru, Kecamatan Sawah Besar, Kota Jakarta Pusat, Daerah Khusus Ibukota Jakarta 10710</p>
                                        </div>
                                        <br /><br />
                                        <div className="button-primary type-brown">
                                            <a target="_blank" rel="noopener noreferrer" href="https://calendar.google.com/event?action=TEMPLATE&amp;tmeid=MGcwMjFyM2FoZzM4YjljMDE2dmxjbThkZWwgd2Vib29zaGNvcnBAbQ&amp;tmsrc=webooshcorp%40gmail.com">
                                                ADD TO GOOGLE CALENDAR
                                            </a>
                                        </div>
                                    </Zoom>
                                </div>
                                <br /><br />

                                {/* <div>
                                    <Suspense fallback={<div>Loading...</div>}>
                                        <Protocol />
                                    </Suspense>
                                </div> */}
                                <Zoom>
                                    <div className="container-text-video txt-size-2rem font-a-b">
                                        Live Wedding
                                    </div>
                                    <br />
                                </Zoom>
                                    <div className="container-video">
                                        <iframe
                                            title="Legend and Renata"
                                            className="video"
                                            src="https://www.youtube.com/embed/kH7wlLOQMNM" 
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen="allowFullScreen">
                                        </iframe>
                                    </div>
                                <br /><br />
                                <div ref={galleryRef} className="container-gallery">
                                    <Zoom>
                                        <p className="txt-size-2rem font-a-b">Gallery</p>
                                        <LightGallery
                                            onInit={onInit}
                                            speed={500}
                                            plugins={[lgThumbnail, lgZoom]}
                                            elementClassNames="lg-custom-thumbnails"
                                        >
                                            <Zoom>
                                                <a className="gallery-item gallery-item--1" href={platinum1}>
                                                    <img className="gallery-img" alt="img1" src={platinum1} data-src={platinum1} loading="lazy"/>
                                                </a>
                                            </Zoom>
                                            <Zoom>
                                                <a className="gallery-item gallery-item--2" href={platinum2}>
                                                    <img className="gallery-img" alt="img2" src={platinum2} data-src={platinum2} loading="lazy" />
                                                </a>
                                            </Zoom>
                                            <Zoom>
                                                <a className="gallery-item gallery-item--3" href={platinum3}>
                                                    <img className="gallery-img" alt="img3" src={platinum3} data-src={platinum3} loading="lazy" />
                                                </a>
                                            </Zoom>
                                            <Zoom>
                                                <a className="gallery-item gallery-item--4" href={platinum5}>
                                                    <img className="gallery-img" alt="img4" src={platinum5} data-src={platinum5} loading="lazy" />
                                                </a>
                                            </Zoom>
                                            <Zoom>
                                                <a className="gallery-item gallery-item--5" href={platinum1}>
                                                    <img className="gallery-img" alt="img5" src={platinum1} data-src={platinum1} loading="lazy" />
                                                </a>
                                            </Zoom>
                                            {/* <a className="gallery-item gallery-item--6" href={platinum2}>
                                                <img className="gallery-img" alt="img6" src={platinum2} data-src={platinum2} loading="lazy" />
                                            </a> */}
                                            {/* <a className="gallery-item gallery-item--7" href={platinum3}>
                                                <img className="gallery-img" alt="img7" src={platinum3} data-src={platinum3} loading="lazy" />
                                            </a> */}
                                            {/* <a className="gallery-item gallery-item--8" href={platinum5}>
                                                <img className="gallery-img" alt="img8" src={platinum5} data-src={platinum5} loading="lazy" />
                                            </a> */}
                                        </LightGallery>
                                    </Zoom>
                                </div>
                                <br /><br />
                                <div ref={quoteRef} className="container-slider">
                                    <Fade>
                                        <div className="container-slider-photo">
                                            <img
                                                className="slide-photo"
                                                src={platinum5}
                                                loading="lazy"
                                                alt="einvitation"
                                                />
                                        </div>
                                    </Fade>
                                </div>
                                <div className="container-quotes">
                                    <div className="container-image-quotes">
                                        <img
                                            className="image-quotes"
                                            src={coklatMuda2}
                                            loading="lazy"
                                            alt="einvitation"
                                        />
                                    </div>
                                    <Zoom>
                                        <div className="container-text font-a txt-size-2rem" style={{ lineHeight: '30px' }}>
                                            "{data.couple.quotation2}"
                                        </div>
                                    </Zoom>
                                </div>
                                <br />

                                <div className="container-timeline">
                                    <Zoom>
                                        <p className="title-timeline txt-size-2rem font-a-b">Love Story</p>
                                    </Zoom>
                                    <Timeline mode={width > 500 ? "alternate" : "left"}>
                                        <Timeline.Item 
                                            dot={
                                                <HeartFilled style={{ fontSize: '16px' }} />
                                            }
                                        >
                                            <Zoom>
                                                <div className="container-timeline-content">
                                                    <p className="font-m-r">Augustus, 8 2018</p>
                                                    <p className="font-m-b">First We Meet</p>
                                                    <p className="font-m-r">
                                                        Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in .
                                                    </p>
                                                </div>
                                            </Zoom>
                                        </Timeline.Item>
                                        <Timeline.Item
                                            dot={
                                                <HeartFilled style={{ fontSize: '16px' }} />
                                            }
                                        >
                                            <Zoom>
                                                <div className="container-timeline-content">
                                                    <p className="font-m-r">Augustus, 18 2018</p>
                                                    <p className="font-m-b">First Date</p>
                                                    <p className="font-m-r">
                                                        Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in .
                                                    </p>
                                                </div>
                                            </Zoom>
                                        </Timeline.Item>
                                        <Timeline.Item
                                            dot={
                                                <HeartFilled style={{ fontSize: '16px' }} />
                                            }
                                        >
                                            <Zoom>
                                                <div className="container-timeline-content">
                                                    <p className="font-m-r">Augustus, 28 2018</p>
                                                    <p className="font-m-b">In A Relationship</p>
                                                    <p className="font-m-r">
                                                        Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in .
                                                    </p>
                                                </div>
                                            </Zoom>
                                        </Timeline.Item>
                                        <Timeline.Item
                                            dot={
                                                <HeartFilled style={{ fontSize: '16px' }} />
                                            }
                                        >
                                            <Zoom>
                                                <div className="container-timeline-content">
                                                    <p className="font-m-r">Augustus, 8 2019</p>
                                                    <p className="font-m-b">We're Engaged</p>
                                                    <p className="font-m-r">
                                                        Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in .
                                                    </p>
                                                </div>
                                            </Zoom>
                                        </Timeline.Item>
                                    </Timeline>
                                </div>
                                <br/>

                                <div className="container-gift">
                                    <Zoom>
                                        <p className="txt-size-2rem dark-brown-txt font-a-b">Digital Gift</p>
                                    </Zoom>
                                    <Zoom>
                                        <p className="font-m-r">Your kind blessing can be sent to this information bellow</p>
                                    </Zoom>
                                    <Zoom>
                                        <div className="btn-gift">
                                            <button className="button-primary type-brown" onClick={() => setOpenModal(!openModal)}>CLICK HERE</button>
                                        </div>
                                    </Zoom>
                                </div>
                                {
                                    openModal ? 
                                        <Popup/>
                                    : null
                                }
                                <br/><br/><br/>
                                <div ref={wishRef} className="container-wishes">
                                    <Zoom>
                                        <p className="txt-size-2rem font-a-b">Wedding Wish</p>
                                        <form onSubmit={(e) => onSubmit(e)}>
                                            <input placeholder="Name" value={name} type="text" maxLength="50" required onChange={(e) => setName(e.target.value)}/>
                                            <textarea placeholder="Wedding Wish" value={wishes} type="text" maxLength="500" rows="4" required onChange={(e) => setWishes(e.target.value)}/>
                                            <div className="add-wishes">
                                                <button className="button-primary type-brown">
                                                    {
                                                        isLoading ? "Sending..." : "SEND WISH"
                                                    }
                                                </button>
                                            </div>
                                        </form>
                                    </Zoom>
                                    <br/><br/>
                                    <Zoom>
                                        <div className="container-wishes-list">
                                            {
                                                wishesList.map((data, index) => (
                                                    <div key={index}>
                                                        <Fade>
                                                            <div>
                                                                <FontAwesomeIcon icon={faPaperPlane} />
                                                            </div>
                                                            <div className="content">
                                                                <p className="name font-m-b txt-size-075rem">{data.name}</p>
                                                                <p className="font-m-r txt-size-075rem">{data.wishes}</p>
                                                            </div>
                                                        </Fade>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </Zoom>
                                </div>
                                <br/><br/>
                                <div className="container-thankyou">
                                    <HeadShake>
                                        <div className="container-text-thankyou txt-size-2rem font-a-b">Thank You</div>
                                    </HeadShake>
                                    <Zoom>
                                        <div className="container-text-thankyou font-ar txt-size-35rem" style={{ lineHeight: '70px' }}>Cipto & Mawar</div>
                                    </Zoom>
                                    <div className="container-image-thankyou">
                                        <img
                                            className="image-thankyou"
                                            src={coklat2}
                                            loading="lazy"
                                            alt="einvitation"
                                        />
                                    </div>
                                </div>
                                <div className="font-m-r" style={{ display: 'none' }}>
                                    <h3>I Believe in You by Celine Dion, ll Divo</h3>
                                    <AudioPlayer src={audioSrc} onPlay={playing} />
                                </div>

                                <Suspense fallback={<div>Loading...</div>}>
                                    <Footer />
                                </Suspense>
                                <div className="menu-sticky">
                                    <div className="container-menu txt-size-075rem font-m-r white-txt">
                                        <div onClick={()=> onChangeRef("home")}>
                                            <img src={home} alt="Index" loading="lazy"/>
                                            <div>HOME</div>
                                        </div>
                                        <div onClick={() => onChangeRef("couple")}>
                                            <img src={couple} alt="Index" loading="lazy" />
                                            <div>COUPLE</div>
                                        </div>
                                        <div onClick={() => onChangeRef("event")}>
                                            <img src={calender} alt="Index" loading="lazy" />
                                            <div>EVENT</div>
                                        </div>
                                        <div onClick={() => onChangeRef("gallery")}>
                                            <img src={gallery} alt="Index" loading="lazy" />
                                            <div>GALLERY</div>
                                        </div>
                                        <div onClick={() => onChangeRef("quote")}>
                                            <img src={quote} alt="Index" loading="lazy" />
                                            <div>QUOTE</div>
                                        </div>
                                        <div onClick={() => onChangeRef("wish")}>
                                            <img src={wish} alt="Index" loading="lazy" />
                                            <div>WISH</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </Fragment>
                    : null
                }
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    Ultimate: state.Ultimate
})
const mapDispatchToProps = {
    ...actions
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Platinum);
