import React, { lazy, useEffect, useState, Suspense, useRef } from 'react'
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../../redux/Ultimate/action'

import data from '../../../data/olin.json'
import AudioPlayer from '../../player/music/music'
import { Fade, Zoom } from 'react-reveal';
import HeadShake from 'react-reveal/HeadShake';
import {
    qrcode1,
    qrcode2,

    olin1,
    olin2,
    olinmuda1,
    olinmuda2,

    platinum1,
    platinum2,
    platinum3,
    platinum5,
    ilustrasi1,
    ilustrasi2,

    home,
    couple,
    gallery,
    calender,
    wish,
    quote,
    bible,
    sezairi,
} from '../../../asset'
import { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt, faMap, faPaperPlane, faMusic, faVolumeMute } from '@fortawesome/free-solid-svg-icons'
import { InstagramOutlined } from '@ant-design/icons'

import LightGallery from 'lightgallery/react';

// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

// If you want you can use SCSS instead of css
import 'lightgallery/scss/lightgallery.scss';
import 'lightgallery/scss/lg-zoom.scss';

// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

// import Splash from './splash.js'
import Splash from '../../splashSimple'
import OurStory from '../../SlickSlider/OurStory'
const Footer = lazy(() => import('../../layout/Footer/ultimate/ultimate'))

const Ultimate = (props) => {
    const { Ultimate, fetchWishesClient, postWishClient, fetchWishes, postWish } = props
    let { isLoading, wishesList } = Ultimate

    let { guest } = useParams();

    const content = useRef(null)
    const homeRef = useRef(null)
    const coupleRef = useRef(null)
    const eventRef = useRef(null)
    const galleryRef = useRef(null)
    const quoteRef = useRef(null)
    const wishRef = useRef(null)

    const [openInv, setOpenInv] = useState(false)
    const [audioSrc, setAudioSrc] = useState('')
    const [playing, setPlaying] = useState(false);
    const [name, setName] = useState("")
    const [wishes, setWishes] = useState("")
    const [openModal, setOpenModal] = useState(false)
    const [rekening1, setRekening1] = useState(7130973757)
    const [rekening2, setRekening2] = useState(7131257841)

    // if (openModal) {
    //     document.body.style.overflow = 'hidden';
    // } else {
    //     document.body.style.overflow = 'unset';
    // }

    useEffect(() => {
        window.scrollTo(0, 0) //Auto scroll top setiap buka
        setAudioSrc(sezairi)
        setPlaying(true)
        // fetchWishes()
        fetchWishesClient(3)
    }, [])

    useEffect(() => {
        if (content.current) {
            setPlaying(true)
            content.current.scrollIntoView({
                behavior: 'smooth'
            });
        }
    }, [openInv])

    const onChangeRef = (ref) => {
        switch (ref) {
            case "home":
                if (homeRef.current) {
                    homeRef.current.scrollIntoView({
                        behavior: 'smooth'
                    });
                }
                break;
            case "couple":
                if (coupleRef.current) {
                    coupleRef.current.scrollIntoView({
                        behavior: 'smooth'
                    });
                }
                break;
            case "event":
                if (eventRef.current) {
                    eventRef.current.scrollIntoView({
                        behavior: 'smooth'
                    });
                }
                break;
            case "gallery":
                if (galleryRef.current) {
                    galleryRef.current.scrollIntoView({
                        behavior: 'smooth'
                    });
                }
                break;
            case "quote":
                if (quoteRef.current) {
                    quoteRef.current.scrollIntoView({
                        behavior: 'smooth'
                    });
                }
                break;
            case "wish":
                if (wishRef.current) {
                    wishRef.current.scrollIntoView({
                        behavior: 'smooth'
                    });
                }
                break;
            default:
                break;
        }
    }

    const onInit = () => {
        console.log('E-Invitation by Weboosh has been initialized');
    };

    const onSubmit = (e) => {
        e.preventDefault()
        if (name !== "" && wishes !== "") {
            let data = {
                id: 4,
                name: name, 
                wishes: wishes
            }
            postWishClient(data)
            // postWish(data)
            setName("")
            setWishes("")
        } else {
            alert("Please Fill Your Name and Wishes First")
        }
    }

    const Popup = () => {

        return (
            <div className='popup'>
                <span className="close" onClick={() => setOpenModal(!openModal)}>&times;</span>
                <div className='popup_inner'>
                    <p className="txt-size-2rem font-a-b">Digital Gift</p>
                    <p className="font-m-r">Your kind blessing can be sent to this information bellow</p>

                    <div className="popup-img">
                        <img
                            className="popup-img-content"
                            src={qrcode2}
                            alt="Rekening"
                        />
                    </div>
                    <button className="btn-popup" onClick={() => {
                        navigator.clipboard.writeText(rekening2)
                        alert(`Copied to clipboard: ${rekening2}`)
                    }}>
                        Copy to Clipboard
                    </button>
                    <br /><br />
                    <div className="popup-img">
                        <img
                            className="popup-img-content"
                            src={qrcode1}
                            alt="Rekening"
                        />
                    </div>
                    <button className="btn-popup" onClick={() => {
                        navigator.clipboard.writeText(rekening1)
                        alert(`Copied to clipboard: ${rekening1}`)
                    }}>
                        Copy to Clipboard
                    </button>
                </div>
            </div>
        )
    }

    return (
        <div id="ultimate-page">
            <div ref={homeRef} className="container-body type-blue-40 type-cream">
                <Splash 
                    onOpen={setOpenInv}
                    guest={guest}
                    coupleName={"Wansah & Olin"}
                    fullDate={"October 23, 2021"}
                    day={30}
                    month={12}
                    srcImg={"https://res.cloudinary.com/weboosh/image/upload/v1633837856/olin/DSCF9345_ijqksp.jpg"}
                />
                {
                    openInv ?
                        <Fragment>
                            <div ref={content} className="content">
                                <div className="container-banners bg-banner">
                                    <div className="container-image-banner">
                                        <img
                                            className="image-banner"
                                            src={olin1}
                                            loading="lazy"
                                            alt="einvitation"
                                        />
                                    </div>
                                    <div className="container-content-banner">
                                        <div className="container-icon-banner">
                                            <Zoom>
                                                <img
                                                    className="icon-banner"
                                                    src={ilustrasi1}
                                                    loading="lazy"
                                                    alt="icon"
                                                />
                                            </Zoom>
                                        </div>
                                        <Zoom>
                                            <div className="text-banner">
                                                <p className="txt-size-1rem font-m-r">{data.verse.one}</p>
                                                <p className="txt-size-075rem font-m-m">{data.verse.oneDesc}</p>
                                            </div>
                                        </Zoom>
                                        <div className="container-image2-banner">
                                            <img
                                                className="image-banner"
                                                src={olinmuda1}
                                                loading="lazy"
                                                alt="einvitation"
                                            />
                                        </div>
                                        <div className="container-image3-banner">
                                            <Fade>
                                                <img
                                                    className="image3-banner"
                                                    src={"https://res.cloudinary.com/weboosh/image/upload/v1633837855/olin/IMG_3164_neozzt.jpg"}
                                                    loading="lazy"
                                                    alt="einvitation"
                                                />
                                            </Fade>
                                        </div>
                                    </div>
                                </div>
                                
                                <div ref={coupleRef} className="container-bride">
                                    <div className="container-icon-bride">
                                        <Zoom>
                                            <img
                                                className="icon-bride"
                                                src={ilustrasi2}
                                                loading="lazy"
                                                alt="icon"
                                            />
                                        </Zoom>
                                    </div>
                                    <div className="title-bride">
                                        <Fade bottom>
                                            <div className="txt-size-2rem font-a-b">The Groom & Bride</div>
                                        </Fade>
                                    </div>
                                    <Zoom>
                                        <div className="container-bride-name">
                                            <div className="font-ar txt-size-2rem">{data.couple.groomName}</div>
                                            <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/wansyahsantosa">
                                                <div className="row justify-content-center align-items-center" style={{ color: 'white', fontSize: '25px', padding: '15px 0px' }}><InstagramOutlined /></div>
                                            </a>
                                            <div className="font-m-r">{data.couple.groomParents}</div>
                                        </div>
                                    </Zoom>
                                    <div className="container-bride-photo">
                                        <Fade left>
                                            <img
                                                className="bride-photo"
                                                src={"https://res.cloudinary.com/weboosh/image/upload/v1633837853/olin/IMG_3150_cmgb52.jpg"}
                                                loading="lazy"
                                                alt="einvitation"
                                            />
                                        </Fade>
                                    </div>
                                    <br /><br />
                                    <div className="container-bride-photo">
                                        <Fade right>
                                            <img
                                                className="bride-photo"
                                                src={"https://res.cloudinary.com/weboosh/image/upload/v1633837851/olin/DSCF9203_k49opm.jpg"}
                                                loading="lazy"
                                                alt="einvitation"
                                            />
                                        </Fade>
                                    </div>
                                    <Zoom>
                                        <div className="container-bride-name">
                                            <div className="font-ar txt-size-2rem">{data.couple.brideName}</div>
                                            <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/olinnn15">
                                                <div className="row justify-content-center align-items-center" style={{ color: 'white', fontSize: '25px', padding: '15px 0px' }}><InstagramOutlined /></div>
                                            </a>
                                            <div className="font-m-r">{data.couple.brideParents}</div>
                                        </div>
                                    </Zoom>
                                </div>
                                
                                <br /><br />
                                <div ref={eventRef} className="container-event">
                                    <Zoom>
                                        <p className="txt-size-2rem font-a-b">Save The Date</p>
                                        <div className="content-event">
                                            <p className="font-m-r">The Wedding Day</p>
                                            <FontAwesomeIcon icon={faCalendarAlt} />
                                            <p className="font-m-r">Saturday, October 23th 2021</p>
                                            <div className="line"></div>
                                            <p className="font-m-r">19.00 - 21.00</p>

                                            <FontAwesomeIcon icon={faMap} />
                                            <p className="font-m-r">Hotel Istana Nelayan</p>
                                            <div className="line"></div>
                                            <p className="font-m-r">Jl. Gatot Subroto Km 5 No.21, RT.003/RW.003, Jatiuwung, Kec. Cibodas, Kota Tangerang, Banten 15134</p>
                                        </div>
                                        <br /><br />
                                        <div className="add-google">
                                            <a target="_blank" rel="noopener noreferrer" href="https://calendar.google.com/event?action=TEMPLATE&amp;tmeid=MmhlNjhobDhuMWtscHE4dGh2cnAzb2NsNHUgd2Vib29zaGNvcnBAbQ&amp;tmsrc=webooshcorp%40gmail.com">
                                                ADD TO GOOGLE CALENDAR
                                            </a>
                                        </div>
                                    </Zoom>
                                </div>
                               
                                <br /><br /> <br /><br />
                                <div ref={galleryRef}></div>
                                 
                                {/* <div className="container-video">
                                    <iframe
                                        title="Legend and Renata"
                                        className="video"
                                        src="https://www.youtube.com/embed/kH7wlLOQMNM"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen="allowFullScreen">
                                    </iframe>
                                </div> */}
                                {/* <br /><br /> */}
                                <div className="container-gallery">
                                    <Zoom>
                                        <div className="container-text-video txt-size-2rem font-a-b">
                                            Gallery
                                        </div>
                                    </Zoom>
                                    <Zoom>
                                        <p className="txt-size-2rem font-a-b">Gallery</p>
                                        <LightGallery
                                            onInit={onInit}
                                            speed={500}
                                            plugins={[lgThumbnail, lgZoom]}
                                            elementClassNames="lg-custom-thumbnails"
                                        >
                                            <Zoom>
                                                <a className="gallery-item gallery-item--1" href={"https://res.cloudinary.com/weboosh/image/upload/v1633837853/olin/IMG_2932_ogzkrq.jpg"}>
                                                    <img className="gallery-img" alt="Wansah&Olin 1" src={"https://res.cloudinary.com/weboosh/image/upload/v1633837853/olin/IMG_2932_ogzkrq.jpg"} data-src={"https://res.cloudinary.com/weboosh/image/upload/v1633837853/olin/IMG_2932_ogzkrq.jpg"} loading="lazy" />
                                                </a>
                                            </Zoom>
                                            <Zoom>
                                                <a className="gallery-item gallery-item--2" href={"https://res.cloudinary.com/weboosh/image/upload/v1633837857/olin/DSCF9553und_ino640.jpg"}>
                                                    <img className="gallery-img" alt="Wansah&Olin 2" src={"https://res.cloudinary.com/weboosh/image/upload/v1633837857/olin/DSCF9553und_ino640.jpg"} data-src={"https://res.cloudinary.com/weboosh/image/upload/v1633837857/olin/DSCF9553und_ino640.jpg"} loading="lazy" />
                                                </a>
                                            </Zoom>
                                            <Zoom>
                                                <a className="gallery-item gallery-item--3" href={"https://res.cloudinary.com/weboosh/image/upload/v1634103930/olin/new7_wpojqy.jpg"}>
                                                    <img className="gallery-img" alt="Wansah&Olin 3" src={"https://res.cloudinary.com/weboosh/image/upload/v1634103930/olin/new7_wpojqy.jpg"} data-src={"https://res.cloudinary.com/weboosh/image/upload/v1634103930/olin/new7_wpojqy.jpg"} loading="lazy" />
                                                </a>
                                            </Zoom>
                                            <Zoom>
                                                <a className="gallery-item gallery-item--4" href={"https://res.cloudinary.com/weboosh/image/upload/v1634103926/olin/new5_tf3f0w.jpg"}>
                                                    <img className="gallery-img" alt="Wansah&Olin 4" src={"https://res.cloudinary.com/weboosh/image/upload/v1634103926/olin/new5_tf3f0w.jpg"} data-src={"https://res.cloudinary.com/weboosh/image/upload/v1634103926/olin/new5_tf3f0w.jpg"} loading="lazy" />
                                                </a>
                                            </Zoom>
                                            <Zoom>
                                                <a className="gallery-item gallery-item--5" href={"https://res.cloudinary.com/weboosh/image/upload/v1634103929/olin/new1_tzbbcw.jpg"}>
                                                    <img className="gallery-img" alt="Wansah&Olin 5" src={"https://res.cloudinary.com/weboosh/image/upload/v1634103929/olin/new1_tzbbcw.jpg"} data-src={"https://res.cloudinary.com/weboosh/image/upload/v1634103929/olin/new1_tzbbcw.jpg"} loading="lazy" />
                                                </a>
                                            </Zoom>
                                            <Zoom>
                                                <a className="gallery-item gallery-item--6" href={"https://res.cloudinary.com/weboosh/image/upload/v1633837849/olin/Prewed_OLIN0566_ad4tvs.jpg"}>
                                                    <img className="gallery-img" alt="Wansah&Olin 6" src={"https://res.cloudinary.com/weboosh/image/upload/v1633837849/olin/Prewed_OLIN0566_ad4tvs.jpg"} data-src={"https://res.cloudinary.com/weboosh/image/upload/v1633837849/olin/Prewed_OLIN0566_ad4tvs.jpg"} loading="lazy" />
                                                </a>
                                            </Zoom>
                                            <Zoom>
                                                <a className="gallery-item gallery-item--7" href={"https://res.cloudinary.com/weboosh/image/upload/v1634103926/olin/new3_ni2gp0.jpg"}>
                                                    <img className="gallery-img" alt="Wansah&Olin 7" src={"https://res.cloudinary.com/weboosh/image/upload/v1634103926/olin/new3_ni2gp0.jpg"} data-src={"https://res.cloudinary.com/weboosh/image/upload/v1634103926/olin/new3_ni2gp0.jpg"} loading="lazy" />
                                                </a>
                                            </Zoom>
                                            <Zoom>
                                                <a className="gallery-item gallery-item--8" href={"https://res.cloudinary.com/weboosh/image/upload/v1634103930/olin/new8_tiwhmn.jpg"}>
                                                    <img className="gallery-img" alt="Wansah&Olin 8" src={"https://res.cloudinary.com/weboosh/image/upload/v1634103930/olin/new8_tiwhmn.jpg"} data-src={"https://res.cloudinary.com/weboosh/image/upload/v1634103930/olin/new8_tiwhmn.jpg"} loading="lazy" />
                                                </a>
                                            </Zoom>
                                            
                                            <Zoom>
                                                <a className="gallery-item gallery-item--9" href={"https://res.cloudinary.com/weboosh/image/upload/v1633837846/olin/Prewed_OLIN0564_adjmfa.jpg"}>
                                                    <img className="gallery-img" alt="Wansah&Olin 9" src={"https://res.cloudinary.com/weboosh/image/upload/v1633837846/olin/Prewed_OLIN0564_adjmfa.jpg"} data-src={"https://res.cloudinary.com/weboosh/image/upload/v1633837846/olin/Prewed_OLIN0564_adjmfa.jpg"} loading="lazy" />
                                                </a>
                                            </Zoom>

                                            <Zoom>
                                                <a className="gallery-item gallery-item--10" href={"https://res.cloudinary.com/weboosh/image/upload/v1634103925/olin/new2_doewff.jpg"}>
                                                    <img className="gallery-img" alt="Wansah&Olin 10" src={"https://res.cloudinary.com/weboosh/image/upload/v1634103925/olin/new2_doewff.jpg"} data-src={"https://res.cloudinary.com/weboosh/image/upload/v1634103925/olin/new2_doewff.jpg"} loading="lazy" />
                                                </a>
                                            </Zoom>

                                            <Zoom>
                                                <a className="gallery-item gallery-item--11" href={"https://res.cloudinary.com/weboosh/image/upload/v1633837850/olin/Prewed_OLIN0672_fepxxs.jpg"}>
                                                    <img className="gallery-img" alt="Wansah&Olin 11" src={"https://res.cloudinary.com/weboosh/image/upload/v1633837850/olin/Prewed_OLIN0672_fepxxs.jpg"} data-src={"https://res.cloudinary.com/weboosh/image/upload/v1633837850/olin/Prewed_OLIN0672_fepxxs.jpg"} loading="lazy" />
                                                </a>
                                            </Zoom>
                                            
                                        </LightGallery>
                                    </Zoom>
                                </div>
                                <br /><br />
                                
                                <div ref={quoteRef} className="container-slider">
                                    <Fade>
                                        <div className="container-slider-photo">
                                            <img
                                                className="slide-photo"
                                                src={"https://res.cloudinary.com/weboosh/image/upload/v1633837846/olin/IMG_3178und_ta0jnc.jpg"}
                                                loading="lazy"
                                                alt="einvitation"
                                            />
                                        </div>
                                    </Fade>
                                </div>
                               
                                <div className="container-quotes">
                                    <div className="container-image-quotes">
                                        <img
                                            className="image-quotes"
                                            src={olinmuda2}
                                            loading="lazy"
                                            alt="einvitation"
                                        />
                                    </div>
                                    <Zoom>
                                        <div className="container-text font-a txt-size-2rem" style={{ lineHeight: '30px' }}>
                                            "{data.couple.quotation2}"
                                        </div>
                                        <div className="container-text font-a txt-size-2rem" style={{ lineHeight: '30px' }}>
                                            {data.couple.quotation2desc}
                                        </div>
                                    </Zoom>
                                </div>
                                
                                <br />
                                <Zoom>
                                    {
                                        <OurStory 
                                            data={data.story}
                                        />
                                    }
                                </Zoom>
                                
                                <div className="container-gift">
                                    <Zoom>
                                        <p className="txt-size-2rem dark-brown-txt font-a-b">Digital Gift</p>
                                    </Zoom>
                                    <Zoom>
                                        <p className="font-m-r">Your kind blessing can be sent to this information bellow</p>
                                    </Zoom>
                                    <Zoom>
                                        <div className="btn-gift txt-size-1rem">
                                            <button className="btn-gift-style" onClick={() => setOpenModal(!openModal)}>CLICK HERE</button>
                                        </div>
                                    </Zoom>
                                </div>

                                
                                <br /><br />
                                {
                                    openModal ?
                                        <Popup />
                                        : null
                                }
                                <br /><br /><br />
                                <div ref={wishRef} className="container-wishes">
                                    <Zoom>
                                        <p className="txt-size-2rem font-a-b">Wedding Wish</p>
                                        <form onSubmit={(e) => onSubmit(e)}>
                                            <input placeholder="Name" value={name} type="text" maxLength="50" required onChange={(e) => setName(e.target.value)} />
                                            <textarea placeholder="Wedding Wish" value={wishes} type="text" maxLength="500" rows="4" required onChange={(e) => setWishes(e.target.value)} />
                                            <div className="add-wishes">
                                                <button className="btn-wishes-style txt-size-1rem">
                                                    {
                                                        isLoading ? "Sending..." : "SEND WISH"
                                                    }
                                                </button>
                                            </div>
                                        </form>
                                    </Zoom>
                                    <br /><br />
                                    <Zoom>
                                        {
                                            wishesList.length > 0 ? 
                                                <div className="container-wishes-list">
                                                    {
                                                        wishesList.map((data, index) => (
                                                            <div key={index}>
                                                                <Fade>
                                                                    <div>
                                                                        <FontAwesomeIcon icon={faPaperPlane} />
                                                                    </div>
                                                                    <div className="content">
                                                                        <p className="name font-m-b txt-size-075rem">{data.name}</p>
                                                                        <p className="font-m-r txt-size-075rem">{data.wishes}</p>
                                                                    </div>
                                                                </Fade>
                                                            </div>
                                                        ))
                                                    }
                                                </div>  
                                            : <div></div>
                                        }
                                    </Zoom>
                                </div>


                                {/* Sampai sini */}
                                <br /><br />
                                <div className="container-thankyou">
                                    <HeadShake>
                                        <div className="container-text-thankyou txt-size-2rem font-a-b">Thank You</div>
                                    </HeadShake>
                                    <Zoom>
                                        <div className="container-text-thankyou font-ar txt-size-35rem" style={{ lineHeight: '70px' }}>Wansah & Olin</div>
                                    </Zoom>
                                    <div className="container-image-thankyou">
                                        <img
                                            className="image-thankyou"
                                            src={olin2}
                                            loading="lazy"
                                            alt="einvitation"
                                        />
                                    </div>
                                </div>
                                {/* Sampai sini */}
                                {/* <div className="font-m-r" style={{ display: 'none' }}>
                                    <AudioPlayer src={audioSrc} onPlay={playing} />
                                </div> */}

                                <a className="music" onClick={() => setPlaying(!playing)}>
                                    {
                                        playing ?
                                            <Fragment>
                                                <div className="font-m-r" style={{ display: 'none' }}>
                                                    <AudioPlayer src={audioSrc} onPlay={playing} />
                                                </div>
                                                <FontAwesomeIcon icon={faMusic} className="my-music" />
                                            </Fragment>
                                        : 
                                            <FontAwesomeIcon icon={faVolumeMute} className="my-music" />
                                    }
                                </a>

                                <Suspense fallback={<div>Loading...</div>}>
                                    <Footer />
                                </Suspense>
                                <div className="menu-sticky">
                                    <div className="container-menu txt-size-075rem font-m-r white-txt">
                                        <div onClick={() => onChangeRef("home")}>
                                            <img src={home} alt="Index" loading="lazy" />
                                            <div>HOME</div>
                                        </div>
                                        <div onClick={() => onChangeRef("couple")}>
                                            <img src={couple} alt="Index" loading="lazy" />
                                            <div>COUPLE</div>
                                        </div>
                                        <div onClick={() => onChangeRef("event")}>
                                            <img src={calender} alt="Index" loading="lazy" />
                                            <div>EVENT</div>
                                        </div>
                                        <div onClick={() => onChangeRef("gallery")}>
                                            <img src={gallery} alt="Index" loading="lazy" />
                                            <div>GALLERY</div>
                                        </div>
                                        <div onClick={() => onChangeRef("quote")}>
                                            <img src={bible} alt="Index" loading="lazy" />
                                            <div>VERSE</div>
                                        </div>
                                        <div onClick={() => onChangeRef("wish")}>
                                            <img src={wish} alt="Index" loading="lazy" />
                                            <div>WISH</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </Fragment>
                        : null
                }
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    Ultimate: state.Ultimate
})
const mapDispatchToProps = {
    ...actions
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Ultimate);
