import React from 'react';
import * as actions from "../../redux/Home/action";
import {connect} from "react-redux";
import bg from '../../asset/images/banner-demo.png';
import '../../asset/styles/_demo.scss';

const Demo = (props) => {
    return <div id="demo-page">
        <div className="default-section-wrapper type-first banner-section">
            <img src={bg} alt="banner" className="banner-img"/>
            <div className="container">
                <div className="row text-wrapper">
                    <div className="col-12">
                        <h1 className="name">Man &amp; Woman</h1>
                        <p className="date">Saturday May 30th, Tangerang, Banten. </p>
                        <a href="#rsvp" className="btn btn-primary btn-wide-sm btn-sm  scroll">BTN</a>
                    </div>
                </div>
            </div>
        </div>
        <div className="default-section-wrapper">
            <div className="date-section">
                <div className="container">
                    <div className="row justify-content-lg-between align-items-center">
                        <div className="col-md-6 mb-5 mb-md-0">
                            <div className="pr-md-4">
                                <h1 className="font-secondary display-4">Save the date</h1>
                                <p className="lead font-weight-300 text-dark-gray opacity-8">Saturday May 30th, 2019 -
                                    28 Tangerang, Banten.</p>
                                <p className="mb-4 pr-5">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci consequuntur cupiditate earum eius ipsam iste iure iusto labore magnam, maiores minus necessitatibus nesciunt quia repellat reprehenderit tempora unde voluptates voluptatum!</p>
                                <div className="countdown row text-center"></div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <img src={bg} alt="banner" className="img"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="default-section-wrapper">
            <div className="story-section">
                <div className="container">
                    <div className="row  justify-content-center">
                        <div className="col-md-12">
                            <div className="text-center mb-5 pb-5">
                                <h1 className="display-4 mb-0">Our Love Story</h1>
                                <p className="w-md-40 mb-0 mx-auto text-dark-gray opacity-8 ">The Story of our love and the
                                    Wedding</p>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="text-center">
                                <div className="mb-3 pb-3">
                                    <h5 className="mb-0"> When Jena Miller Met Mike Shneider</h5>
                                    <span className="small text-dark-gray opacity-8">That day changed everything</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <img src={bg} alt="banner" className="img"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
}

const mapStateToProps = (state) => ({
    Demo: state.Demo
})
const mapDispatchToProps = {
    ...actions
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Demo);