export const acropolisCompanyId = 1;
export const data = {
    firstColor: "#24094C",
    secondColor: "#ef9c63",
    thirdColor: "#48abe0",
    fourthColor: "#d11ca4",
    eventTitle: "Acropolis Agency",
    eventTitle2: "1st Anniversary",
    eventDescription: ["OSHIEN ZUKY | ALKA FLOW | NADHYA CELLO |", "LIA CHRIS | RONALD 3D | MARSHA | AND MANY MORE"],
    eventDate: "18 Januari 2023",
    eventDays: "Senin 10.00 AM",
    eventDressCode: "Blue & White (Sporty Casual)",
    eventLocation: "AS BAR & RESTO SERPONG",
    additionalInformation: "Pintu dibuka pukul 12.00 WIB",
    website: "www.acropolis.co.id",
    websiteLink: "https://acropolis.co.id/",
    instagram: "@acropolis_agency",
    instagramLink: "https://www.instagram.com/acropolis_agency/",
    tiktokUsername: "@acropolis.agency",
    tiktokLink: "https://www.tiktok.com/@acropolis.agency",
    moto: "SEE YOU ON ACRO!",
    noNeed: [
        "/acropolis_asset/no-flower.png",
        "/acropolis_asset/no-gift.png",
        "/acropolis_asset/no-message.png",
    ],
    tiktok:[
        "/acropolis_asset/tikok_universe.png"
    ],
    prepare: [
        {
            name: "POWER BANK",
            asset: "/acropolis_asset/power-bank-2.png"
        },
        {
            name: "QUOTA INTERNET",
            asset: "/acropolis_asset/wifi.png"
        }
    ]
}

export const optionMainCourse = [
    {
        value: "404",
        label: "Pilih Main Course",
    },
    {
        value: "Fish & Chips",
        label: "Fish & Chips"
    },
    {
        value: "Spaghetti Bolognaise",
        label: "Spaghetti Bolognaise"
    },
    {
        value: "Nasi Sapi Lada Hitam",
        label: "Nasi Sapi Lada Hitam"
    },
    {
        value: "Nasi Ayam Cabai Garam",
        label: "Nasi Ayam Cabai Garam"
    },
    {
        value: "Mie Goreng Special",
        label: "Mie Goreng Special"
    },
];

export const optionDessert = [
    {
        value: "404",
        label: "Pilih Dessert",
    },
    {
        value: "Choco Lava",
        label: "Choco Lava"
    },
    {
        value: "Waffle",
        label: "Waffle"
    },
    {
        value: "Pancake",
        label: "Pancake"
    },
];

export const optionDrink = [
    {
        value: "404",
        label: "Pilih Drink",
    },
    {
        value: "Ice Lychee Tea",
        label: "Ice Lychee Tea"
    },
    {
        value: "Ice Lemon Tea",
        label: "Ice Lemon Tea"
    },
    {
        value: "Ice Tea",
        label: "Ice Tea"
    },
];

export const optionLiquor = [
    {
        value: "404",
        label: "Pilih Liquor",
    },
    {
        value: "Capirosca",
        label: "Capirosca"
    },
    {
        value: "Whiskey Sour",
        label: "Whiskey Sour"
    },
    {
        value: "Heineken",
        label: "Heineken"
    },
];