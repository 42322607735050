import { all } from 'redux-saga/effects';

import Home from './Home/saga'
import Ultimate from './Ultimate/saga'
import Acropolis from './Acropolis/saga'

export default function* rootSaga(getState){
    yield all([
        Home(),
        Ultimate(),
        Acropolis()
    ])
}