import React, { Fragment, lazy, useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../../redux/Ultimate/action'
import AudioPlayer from '../../player/music/music'
import { Fade, Zoom } from 'react-reveal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMusic, faVolumeMute } from '@fortawesome/free-solid-svg-icons'

import {
    olin1,
    olin2,
    olinmuda1,
    olinmuda2,

    ilustrasi1,
    ilustrasi2,

    thegift,

    ginia1,
    ginia2,
    ginia3,
    ginia4,
    ginia5,
    ginia6,
    ginia7,
    ginia8,
    ginia9,
    ginia10,
    ginia11,
    ginia12,
    ginia13,
    ginia14,
    ginia15,
} from '../../../asset'

import data from '../../../data/ginia.json'

import Splash from '../../splashSimple'
import Banner from '../../bannerSimple'
import Couple from '../../coupleSimple'
import Event from '../../eventSimple'
import LiveStreaming from '../../liveStreamingSimple'
import Gallery from '../../gallerySimple'
import Quote from '../../quoteSimple'
import OurStory from '../../SlickSlider/OurStory'
import Gift from '../../giftSimple'
import Wishes from '../../wishesSimple'
import Thankyou from '../../thankyouSimple'
import Footer from '../../layout/Footer/ultimate/ultimate'
import Menu from '../../menuSecond'

const Index = (props) => {
    const { Ultimate, fetchWishesClient, postWishClient } = props
    let { isLoading, wishesList } = Ultimate

    const [openInv, setOpenInv] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [name, setName] = useState("")
    const [wishes, setWishes] = useState("")
    const [playing, setPlaying] = useState(false)
    const [audioSrc, setAudioSrc] = useState('')

    let { guest } = useParams();

    const homeRef = useRef(null)
    const content = useRef(null)
    const coupleRef = useRef(null)
    const eventRef = useRef(null)
    const galleryRef = useRef(null)
    const quoteRef = useRef(null)
    const wishRef = useRef(null)

    let imageGallery = {
        gallery:{
            "img1": ginia1,
            "img2": ginia2,
            "img3": ginia3,
            "img4": ginia4,
            "img5": ginia5,
            "img6": ginia6,
            "img7": ginia7,
            "img8": ginia8,
            "img9": ginia9,
            "img10": ginia10,
            "img11": ginia11,
            "img12": ginia12,
            "img13": ginia13,
        }
    }

    let account = {
        "qrCode1": ginia15,
        "qrCode2": ginia14,
        "rekening1" : "7110298000",
        "rekening2" : "4830275440"
    }

    useEffect(() => {
        window.scrollTo(0, 0) //Auto scroll top setiap buka
        setAudioSrc(thegift)
        setPlaying(true)
        fetchWishesClient(4)
    }, [])

    useEffect(() => {
        if (content.current) {
            setPlaying(true)
            content.current.scrollIntoView({
                behavior: 'smooth'
            });
        }
    }, [openInv])

    const onSubmit = (e) => {
        e.preventDefault()
        if (name !== "" && wishes !== "") {
            let data = {
                id: 4,
                name: name, 
                wishes: wishes, 
            }
            postWishClient(data)
            setName("")
            setWishes("")
        } else {
            alert("Please Fill Your Name and Wishes First")
        }
    }

    const onChangeRef = (ref) => {
        switch (ref) {
            case "home":
                if (homeRef.current) {
                    homeRef.current.scrollIntoView({
                        behavior: 'smooth'
                    });
                }
                break;
            case "couple":
                if (coupleRef.current) {
                    coupleRef.current.scrollIntoView({
                        behavior: 'smooth'
                    });
                }
                break;
            case "event":
                if (eventRef.current) {
                    eventRef.current.scrollIntoView({
                        behavior: 'smooth'
                    });
                }
                break;
            case "gallery":
                if (galleryRef.current) {
                    galleryRef.current.scrollIntoView({
                        behavior: 'smooth'
                    });
                }
                break;
            case "quote":
                if (quoteRef.current) {
                    quoteRef.current.scrollIntoView({
                        behavior: 'smooth'
                    });
                }
                break;
            case "wish":
                if (wishRef.current) {
                    wishRef.current.scrollIntoView({
                        behavior: 'smooth'
                    });
                }
                break;
            default:
                break;
        }
    }

    return (
        <div id="ultimate-page">
            <div ref={homeRef} className="container-body type-pink-20 type-grey-30">
                <Splash 
                    onOpen={setOpenInv}
                    guest={guest}
                    hashtag={"#BEcomeOne #BEinLove"}
                    coupleName={"Bonar & Euginia"}
                    fullDate={data.moment.fullDate}
                    day={26}
                    month={12}
                    srcImg={ginia5}
                />
                {
                    openInv ? 
                        <Fragment>
                            <div ref={content} className="content">
                                <Banner
                                    imgLineTop={olin1}
                                    imgLineBtm={olinmuda1}
                                    illustration={ilustrasi1}
                                    data={data}
                                    imgSrc={ginia11}
                                />
                                <div ref={coupleRef}>
                                    <Couple
                                        illustrtaion={ilustrasi2}
                                        data={data}
                                        imgGroom={ginia2}
                                        imgBride={ginia1}
                                    /> 
                                </div>
                                <br/><br/>
                                <div ref={eventRef}>
                                    <Event
                                        fullDate={data.moment.fullDate}
                                        // time={"string"}
                                        holyMatrimony={data.location.holyMatrimony}
                                        holyMatrimonyTime={data.moment.holyMatrimonyTime}
                                        holyMatrimonyDetails1={data.location.holyMatrimonyDetails1}
                                        holyMatrimonyDetails2={data.location.holyMatrimonyDetails2}
                                        holyMatrimonyDetails3={data.location.holyMatrimonyDetails3}
                                        holyMatrimonyDetails4={data.location.holyMatrimonyDetails4}
                                        reception={data.location.reception}
                                        receptionTime={data.moment.receptionTime}
                                        receptionDetails={data.location.receptionDetails}
                                        calendar={"https://calendar.google.com/event?action=TEMPLATE&tmeid=N2tuM2Fydjk4MWxiczNsb2E3bDViYzhzOHQgd2Vib29zaGNvcnBAbQ&tmsrc=webooshcorp%40gmail.com"}
                                    /> 
                                </div>
                                <br/><br/><br/>

                                <LiveStreaming
                                    title={"Bonar & Euginia"}
                                    url={"https://www.youtube.com/embed/lO9Z44q3EMw"}
                                />

                                <br/><br/><br/>
                                <div ref={galleryRef}>
                                    <Gallery
                                        data={imageGallery}
                                        altName={"Bonar & Euginia"}
                                    />
                                </div>

                                <div ref={quoteRef}>
                                    <Quote
                                        imgSrc={ginia12}
                                        illustration={olinmuda2}
                                        data={data}
                                    /> 
                                </div>

                                <Zoom>
                                    <OurStory 
                                        data={data.story}
                                    />
                                </Zoom>

                                <br/><br/>
                                <Gift
                                    openModal={openModal}
                                    setOpenModal={setOpenModal}
                                    data={account}
                                /> 

                                <br/><br/><br/>
                                <div ref={wishRef}>
                                    <Wishes
                                        name={name}
                                        setName={setName}
                                        wishes={wishes}
                                        setWishes={setWishes}
                                        wishesList={wishesList}
                                        isLoading={isLoading}
                                        onSubmit={(e) => onSubmit(e)}
                                    /> 
                                </div>
                                <br/><br/><br/>
                                <Thankyou
                                    name={"Bonar Gunawan & Euginia Salim"}
                                    illustration={olin2}
                                /> 

                                <a className="music" onClick={() => setPlaying(!playing)}>
                                    {
                                        playing ?
                                            <Fragment>
                                                <div className="font-m-r" style={{ display: 'none' }}>
                                                    <AudioPlayer src={audioSrc} onPlay={playing} />
                                                </div>
                                                <FontAwesomeIcon icon={faMusic} className="my-music" />
                                            </Fragment>
                                        : 
                                            <FontAwesomeIcon icon={faVolumeMute} className="my-music" />
                                    }
                                </a>

                                <Footer/>

                                <Menu
                                    onChangeRef={(ref) => onChangeRef(ref)}
                                />
                            </div>
                        </Fragment>
                    : null
                }
             </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    Ultimate: state.Ultimate
})
const mapDispatchToProps = {
    ...actions
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Index);
