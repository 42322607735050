import React from 'react'
import { Fade, Zoom } from 'react-reveal';
import { message } from 'antd';

{/* How To Use Component

<Gift
    openModal={openModal}
    setOpenModal={setOpenModal}
    data={data.account}
/> 

*/}

const GiftSimple = (props) => {
    const { openModal, setOpenModal, data } = props

    const Popup = () => {

        const info = (number) => {
            navigator.clipboard.writeText(number)
            message.success(`Copied to clipboard: ${number}`)
        }

        return (
            <div className='popup'>
                <span className="close" onClick={() => setOpenModal(!openModal)}>&times;</span>
                <div className='popup_inner'>
                    <p className="txt-size-2rem font-a-b">Digital Gift</p>
                    <p className="font-m-r">Your kind blessing can be sent to this information bellow</p>

                    <div className="popup-img">
                        <img
                            className="popup-img-content"
                            src={data.qrCode1}
                            alt="Rekening"
                        />
                    </div>
                    <button className="btn-popup" onClick={() => {
                        info(data.rekening1)
                    }
                    }>
                        Copy to Clipboard
                    </button>
                    <br /><br />
                    <div className="popup-img">
                        <img
                            className="popup-img-content"
                            src={data.qrCode2}
                            alt="Rekening"
                        />
                    </div>
                    <button className="btn-popup" onClick={() => {
                        info(data.rekening2)
                    }}>
                        Copy to Clipboard
                    </button>
                </div>
            </div>
        )
    }

    return (
        <div className="container-gift">
            <Zoom>
                <p className="txt-size-2rem dark-brown-txt font-a-b">Digital Gift</p>
            </Zoom>
            <Zoom>
                <p className="font-m-r">Your kind blessing can be sent to this information bellow</p>
            </Zoom>
            <Zoom>
                <div className="btn-gift txt-size-1rem">
                    <button className="btn-gift-style" onClick={() => setOpenModal(!openModal)}>CLICK HERE</button>
                </div>
            </Zoom>

            {
                openModal ?
                    <Popup />
                : null
            }
        </div>
    )
}

export default GiftSimple
