import * as types from "../types";

export const fetchAttandee = (property, value) => {
    return {
        type: types.ACROPOLIS_GET_ATTENDE_LIST,
        property, value
    }
}

export const registerAttendance = (dataAction) => {
    return {
        type: types.ACROPOLIS_REGISTER_ATTENDANCE,
        dataAction
    }
}

export const handleStateGlobal = (property, value) => {
    return {
        type: types.ACROPOLIS_HANDLE_GLOBAL_STATE,
        property,
        value
    }
}