import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import _ from "lodash";

const SlickSlider = (props) => {
    const [settings, setSettings] = useState({
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "60px",
        slidesToShow: 3,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                    centerMode: false,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                }
            }
        ]
    });

    useEffect(() => {
        if (props.settings) {
            setSettings(props.settings)
        }
    });

    return (
        <div className={"slick-slider-wrapper " + _.get(props, "class", "")}>
            <Slider {...settings}>
                {
                    _.get(props, "data", []).map((item) => {
                        return <div className="item-wrapper" key={item.id}>
                            <div className="item">
                                {
                                    item.icon
                                        ? <img className="img" src={item.icon} alt={item.title}/>
                                        : null
                                }
                                <h3 className="title">{item.title}</h3>
                                <p className="description font-m-r">{item.description}</p>
                            </div>
                        </div>
                    })
                }
            </Slider>
        </div>
    )
}

export default SlickSlider;