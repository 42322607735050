import React, { useEffect, useState, createRef } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../redux/Home/action'
import { Fade, Zoom } from 'react-reveal';
import HeadShake from 'react-reveal/HeadShake';
import AudioPlayer from '../../player/music/music'
// import Navbar from '../../components/layout/Navbar'
// import Carousel from '../../components/carousel/carousel'

import {
    foto1, foto2, foto3, foto4, 
} from '../../../asset'
import believeinyou from '../../../asset/musics/believeinyou.mp3'

const Basic = (props) => {
    const [showVideo, setShowVideo] = useState(false);
    const container = createRef();
    const music = createRef()
    // const [audio] = useState(new Audio(believeinyou));
    const [audioSrc, setAudioSrc] = useState('')
    const [playing, setPlaying] = useState(false);

    const dataBasic = {
        imageOne: foto1,
        useAudio: false,
        firstColor: "#DCDEDD",
        secondColor: "#5D5D5D",
        thirdColor: "#FFFFFF",
        quoteOne: "Together with their families",
        quoteTwo: "We're getting hitched! And we'd be delighted if you could join our virtual wedding!",
        descriptionOne: "Request the honor of your presence on their Holy" +
            " Matrimony and Reception virtual",
        date: "22 FEBRUARY 2021",
        time: "10.00 AM for Holy Matrimony & 13.00 PM for Reception",
        linkLive: "https://www.youtube.com/embed/KM1jkox1QSI",
        man: {
            name: "lorem",
            status: "Groom",
        },
        woman: {
            name: "ipsum",
            status: "Bride",
        },
        carousel: [
            foto2,
            foto3,
            foto4,
        ]
    };

    useEffect(() => {
        window.scrollTo(0, 0) //Auto scroll top setiap buka
        // setPlaying(true);

        return () => {
            // audio.pause()
            // audio.removeEventListener('ended', () => setPlaying(false));
        };
    }, []);

    const onVideoIntersection = (entries) => {
        if (!entries || entries.length <= 0) {
            return;
        }

        if (entries[0].isIntersecting) {
            setShowVideo(true);
            videoObserver.disconnect();
        }
    }
    const videoObserver = new IntersectionObserver(onVideoIntersection, {
        rootMargin: '200px 0px',
        threshold: 0.25
    });

    const onMusicIntersection = (entries) => {
        if (!entries || entries.length <= 0) {
            return;
        }

        if (entries[0].isIntersecting) {
            setAudioSrc(believeinyou)
            musicObserver.disconnect();
        }
    }
    const musicObserver = new IntersectionObserver(onMusicIntersection, {
        rootMargin: '0px 0px',
        threshold: 0.25
    });

    useEffect(() => {
        if (window && 'IntersectionObserver' in window) {
            if (container && container.current) {
                videoObserver.observe(container.current);
            }
        } else {
            setShowVideo(true);
        }
    }, [container])

    useEffect(() => {
        if (window && 'IntersectionObserver' in window) {
            if (music && music.current) {
                musicObserver.observe(music.current);
            }
        } else {
            setAudioSrc(believeinyou)
        }
    }, [music])

    // useEffect(() => {
    //     playing ? audio.play() : audio.pause();
    // },[playing])

    return (
        <div id="basic-page">
            <div className="banner-wrapper" style={{background: dataBasic.firstColor}}>
                <div className="container">
                    <div className="date-wrapper">
                        <h3>{dataBasic.date}</h3>
                    </div>
                    <div className="quotes-wrapper">
                        <h1>{dataBasic.quoteOne}</h1>
                    </div>
                    <div className="name-wrapper">
                        <h3>{dataBasic.man.name + " "} & {" " + dataBasic.woman.name}</h3>
                    </div>
                </div>
            </div>

            <div className="banner">
                <img
                    className="image"
                    src={dataBasic.imageOne}
                    loading="lazy"
                    alt="image-one"
                />
            </div>

            <div className="couple">
                <div className="bride-groom font-a-b">
                    <Fade left>
                        <h1>{dataBasic.man.name}</h1>
                        <section>
                            <h3 className="font-m-r">{dataBasic.man.status}</h3>
                        </section>
                    </Fade>
                </div>
                <Zoom><h1 className="sign font-a-b">&</h1></Zoom>
                <div className="bride-groom font-a-b">
                    <Fade right>
                        <h1>{dataBasic.woman.name}</h1>
                        <section>
                            <h3 className="font-m-r">{dataBasic.woman.status}</h3>
                        </section>
                    </Fade>
                </div>
            </div>
            <div className="container-content font-m-r">
                <Fade top>
                    <h3>{dataBasic.descriptionOne}</h3>
                    <h1 className="font-m-b">{dataBasic.date}</h1>
                    <section>
                        <h3>{dataBasic.time}</h3>
                    </section>
                </Fade>
            </div>
            <Zoom>
                <div className="image-content-container">
                    {
                        dataBasic?.carousel.map((item, index) => {
                            return <div className="image-content" key={index}>
                                <img
                                    className="image"
                                    src={item}
                                    loading="lazy"
                                    alt={"carousel-" + index}
                                />
                            </div>
                        })
                    }
                </div>
            </Zoom>
            <div className="container-content font-m-r">
                <Fade top><h1>Live Streaming</h1></Fade>
            </div>
            <div ref={container} className="cotainer-video">
                {
                    showVideo ?
                        <iframe
                            title="Legend and Renata"
                            className="video"
                            src={dataBasic.linkLive}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen="allowFullScreen">
                        </iframe>
                        : null
                }
            </div>
            <div className="container-quote font-m-m">
                <HeadShake>
                    <h1>{dataBasic.quoteTwo}</h1>
                </HeadShake>
            </div>
            {/* <div className="container-content font-m-r">
                <h1>I Believe in You by Celine Dion, ll Divo</h1>
                <button onClick={() => setPlaying(!playing)}>{playing ? "Pause" : "Play"}</button>
            </div> */}
{/*            <div className="container-content-music font-m-r">
                <h3>I Believe in You by Celine Dion, ll Divo</h3>
                <AudioPlayer src={audioSrc} onPlay={playing} />
            </div>*/}
        </div>
    )
}

const mapStateToProps = (state) => ({
    Home: state.Home
})
const mapDispatchToProps = {
    ...actions
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Basic);