import React, {Fragment, lazy, Suspense} from 'react';

const Header = lazy(() => import('../../components/layout/Header/index'));
const Footer = lazy(() => import('../../components/layout/Footer/index'));
const Banner = lazy(() => import('./Banner/index'));
const Reason = lazy(() => import('./Reason/index'));
const Feature = lazy(() => import('./Feature/index'));
const About = lazy(() => import('./About/index'));
const Product = lazy(() => import('./Product/index'));

const homeContainer = () => {
    return (
        <Fragment>
            <Suspense fallback={<div>Loading...</div>}>
                <div id="home-web">
                    <Header/>
                    <Banner/>
                    <About/>
                    <Reason/>
                    <Feature/>
                    <Product/>
                    <Footer/>
                </div>
            </Suspense>
        </Fragment>
    )
}

export default homeContainer
