import React, {Fragment, useState} from 'react';
import { useParams } from 'react-router-dom';
import * as actions from "../../../redux/Ultimate/action";
import {connect} from "react-redux";
import {
    platinum1,
    platinum2,
    platinum3,
    platinum4
} from "../../../asset";
import TwoSidedImages from "../../twoSidedImages";
import GroomSideBySide from "../../groomSideBySide";
import FullImage from "../../fullImage";
import DateAndPlaceCircle from "../../dateAndPlaceCircle";
import FooterSecond from "../../footerSecond";
import Splash from "../../splashSimple";

const Gold = (props) => {
    const [isOpen, setIsOpen] = useState(false)
    let { guest } = useParams();
    const sectionOneData = {
        className: "second-color",
        photo: platinum1,
        text: "“He makes all things beautiful in His time”",
        author: "Ecclesiastes 3:11"
    }
    const sectionOTwoData = {
        className: "orange-bg",
        title: "Groom and Bride",
        description: "By the grace of God, we are pleased to announce our wedding to you our family and friends:",
        groom: [
            {
                photo: platinum2,
                nickname: "Jack",
                name: "Jack qwerty",
                description: "Mr. lorem lorem / Zhou lorem lorem & Mrs." +
                    "  lorem /" +
                    " lorem Li lorem",
                instagramLink: "https://www.instagram.com/weboosh/"
            },
            {
                photo: platinum3,
                nickname: "Mila",
                name: "Mila Aasdef",
                description: "Mr. lorem lorem / Chen lorem Hie & Mrs.  lorem / Lim lorem Fui",
                instagramLink: "https://www.instagram.com/weboosh/"
            }
        ],
        componentClassName: "no-margin-bottom",
    }
    const sectionThreeData = {
        photo: platinum4
    }
    const sectionFourData = {
        title: "A Million Dreams with a Thousands Miles Journey",
        subTitle: "Nothing is Impossible when we are together",
        events: [
            {
                title: "Holy Matrimony",
                date: "Thursday, December 9th 2021",
                time: "09.00 WIB"
            }
        ],
        componentClassName: "no-margin-top no-margin-bottom",
    }
    const footerData = {
        componentClassName: "dark-brown-bg",
    }

    return <Fragment>
{/*        <Splash
            onOpen={isOpen}
            guest={""}
            coupleName={"John Doe"}
            coupleName2={"Jane Doe"}
            fullDate={"22 December 2022"}
            day={22}
            month={12}
            srcImg={platinum1}
        />*/}
        {
            !isOpen ?
                <div id="gold-page">
                    <TwoSidedImages data={sectionOneData} />
                    <GroomSideBySide data={sectionOTwoData} />
                    <FullImage data={sectionThreeData} />
                    <DateAndPlaceCircle data={sectionFourData} />
                    <FooterSecond data={footerData} />
                </div>
            :
                null
        }
    </Fragment>
}

const mapStateToProps = (state) => ({
    Gold: state.Gold
})

const mapDispatchToProps = {
    ...actions
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Gold);