import React from 'react'
import { Fade, Zoom } from 'react-reveal';

{/* How To Call Component
    <Banner
        imgLineTop={imgSrc}
        imgLineBtm={imgSrc}
        illustration={imgIllustration}
        data={data}
        imgSrc={imgSrc}
    /> 
*/}

const BannerSimple = (props) => {
    const {imgLineTop, imgLineBtm, illustration, data, imgSrc} = props
    return (
        <div className="container-banners bg-banner">
            <div className="container-image-banner">
                {/* <img
                    className="image-banner"
                    src={imgLineTop}
                    loading="lazy"
                    alt="einvitation"
                /> */}
            </div>
            <div className="container-content-banner">
                <div className="container-icon-banner">
                    <Zoom>
                        <img
                            className="icon-banner"
                            src={illustration}
                            loading="lazy"
                            alt="icon"
                        />
                    </Zoom>
                </div>
                <Zoom>
                    <div className="text-banner">
                        <p className="txt-size-1rem font-m-r">{data.verse.one}</p>
                        <p className="txt-size-075rem font-m-m">{data.verse.oneDesc}</p>
                    </div>
                </Zoom>
                <div className="container-image2-banner">
                    <img
                        className="image-banner"
                        src={imgLineBtm}
                        loading="lazy"
                        alt="einvitation"
                    />
                </div>
                <div className="container-image3-banner">
                    <Fade>
                        <img
                            className="image3-banner"
                            src={imgSrc}
                            loading="lazy"
                            alt="einvitation"
                        />
                    </Fade>
                </div>
            </div>
        </div>
    )
}

export default BannerSimple
