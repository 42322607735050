import React, { useState, useEffect } from 'react'
import { Fade, Zoom } from 'react-reveal';

// Example Call Component
{/* 
    <Splash 
        onOpen={setOpenInv}
        guest={guest}
        hashtag={"#BeMyCouple"}
        coupleName={"Couple & Name"}
        fullDate={"October 23, 2021"}
        day={30}
        month={12}
        srcImg={"https://res.cloudinary.com/weboosh/image/upload/v1638888052/sample_b04oen.webp"}
    /> 
*/}

const Splash = (props) => {
    const [timeLeft, setTimeLeft] = useState('');
    const { onOpen, coupleName, coupleName2, guest, hashtag, fullDate, day, month, srcImg, dataCouple } = props

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
            // setYear(new Date().getFullYear());
        }, 1000);
        // Clear timeout if the component is unmounted
        return () => clearTimeout(timer);
    });

    const timerComponents = [];
    Object.keys(timeLeft).forEach((interval, index) => {
        if (!timeLeft[interval]) {
            return;
        }
        timerComponents.push(
            <div key={index}>
                <div className="interval-date font-m-r txt-size-1rem">
                    {timeLeft[interval]}
                </div>
                <div>
                    {interval}
                </div>
            </div>
        );
    });

    const calculateTimeLeft = () => {
        let year = new Date().getFullYear();
        const difference = new Date(`${month}/${day}/${year}`) - new Date();
        let timeLeft = {};
        if (difference > 0) {
            timeLeft = {
                Days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                Hours: Math.floor((difference / (1000 * 60 * 60)) % 24) + 19,
                Minutes: Math.floor((difference / 1000 / 60) % 60),
                Seconds: Math.floor((difference / 1000) % 60)
            };
        }
        return timeLeft;
    };

    const onChange = () => {
        onOpen(true)
    }

    return (
        <div>
            <div className="container-header">
                <div className="container-image">
                    <img
                        className="image-header"
                        src={srcImg}
                        loading="lazy"
                        alt="image"
                    />
                    <div className="container-text">
                        <Fade left><div className="text-header font-m-b txt-size-1rem">The Wedding of</div></Fade>
                        {/* <Fade top><div className="text-header font-m-r txt-size-1rem">By the grace of God, we Invite you to share in their joy at wedding of</div></Fade> */}

                        {/* <Fade left><div className="text-header font-m-m txt-size-1rem">{ dataCouple.couple.groomParents }</div></Fade>
                        <Fade left><div className="text-header font-m-m txt-size-1rem">{ dataCouple.couple.brideParents }</div></Fade> */}
                        
                        <Zoom><div className="text-header font-ar txt-size-35rem" style={{ lineHeight: '70px' }}>{coupleName}</div></Zoom>
                        {
                            coupleName2 ?
                                <Zoom><div className="text-header font-ar txt-size-3rem" style={{ lineHeight: '70px' }}>&</div></Zoom>
                                : null
                        }
                        <Zoom><div className="text-header font-ar txt-size-3rem" style={{ lineHeight: '70px' }}>{coupleName2}</div></Zoom>
                        <Fade right>
                            <div className="text-header font-m-r txt-size-1rem">

                                <strong className="font-m-b">
                                    {
                                        guest ? `Dear ${guest},` : ""
                                    }
                                </strong>
                            </div>
                        </Fade>
                        <Fade right><div className="text-header font-m-r txt-size-1rem">We request the honor of your presence to join on our wedding!</div></Fade>
                        {/* <Zoom><div className="button-open" onClick={() => onChange()}>Open Invitation</div></Zoom> */}
                    </div>
                    <div className="container-date">
                        <Zoom><div className="button-open" onClick={() => onChange()}>Open Invitation</div></Zoom>
                        <br></br>
                        <Fade bottom><div className="text-header font-m-r txt-size-1rem">{dataCouple.couple.hashtag ? dataCouple.couple.hashtag : ""}</div></Fade>
                        <Fade bottom><div className="text-header font-m-r txt-size-1rem">{fullDate}</div></Fade>
                    </div>

                    <div className="container-date-countdown">
                        {timerComponents.length ? timerComponents : null}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Splash
