import React from 'react'
import { Zoom } from 'react-reveal';

// How to Use Component
{/* 
    <LiveStreaming
        title={"Couple Name"}
        url={"https://www.youtube.com/embed/kH7wlLOQMNM"}
    /> 
*/}


const LiveStreamingSimple = (props) => {
    const { title, url } = props
    return (
        <div>
            <Zoom>
                <div className="container-text-video txt-size-2rem font-a-b">
                    Live Wedding
                </div>
                <br />
            </Zoom>
            <div className="container-video">
                <iframe
                    title={title}
                    className="video"
                    src={url} 
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen="allowFullScreen">
                </iframe>
            </div>
        </div>
    )
}

export default LiveStreamingSimple
