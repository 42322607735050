import React from "react";
import SlickSlider from "../index";

// How to Use Component
{/* 
    <OurStory 
        data={data.story}
    /> 
*/}

const OurStory = (props) => {
    const { data } = props
    const settings = {
        className: "center",
        centerMode: false,
        infinite: true,
        dots: true,
        adaptiveHeight: true,
        centerPadding: "60px",
        slidesToShow: 1,
        autoplay: true,
        speed: 3000,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true,
                    adaptiveHeight: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                    centerMode: false,
                    adaptiveHeight: true,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                    adaptiveHeight: true,
                }
            }
        ]
    };

    // const data = [
    //     {
    //         id: 0,
    //         title: "First Meet",
    //         description: "Kami Saling mengenal ketika kami berdua duduk di bangku Sekolah Menengah Pertama pada 2012, aku sering bermain ke rumah sahabatku yaitu Grace yang merupakan saudara dari wansah.",
    //     },
    //     {
    //         id: 1,
    //         title: "First Reunion",
    //         description: "Setelah lama tidak berhubungan, kami kembali" +
    //             " dipertemukan ketika kami menghadiri touring motor ke puncak.",
    //     },
    //     {
    //         id: 2,
    //         title: "Dating",
    //         description: "Kemudian pada tanggal 1 Oktober 2020 hubungan kami" +
    //             " berjalan lancar dan karena bantuan dari teteh Sara spv" +
    //             " dikantor kami masuk ke" +
    //             " jenjang" +
    //             " berikutnya yaitu kami saling berkomitment untuk menjadi pasangan.",
    //     },
    //     {
    //         id: 3,
    //         title: "Engagement",
    //         description: "Tuhan mengijinkan kami berjalan ke jenjang" +
    //             " berikutnya pada tahun 2021 tepatnya 14 Februari kami dapat mengadakan acara lamaran, yang seharusnya diikuti sangjit pada tanggal 15 Agustus, namun digagalkan karna pandemi.",
    //     },
    //     {
    //         id: 4,
    //         title: "Wedding day",
    //         description: "Pada akhirnya tanggal 23 Oktober kami" +
    //             " memutuskan untuk lebih serius dan melanjutkan hubungan kami ke jenjang pernikahan.",
    //     },
    // ];

    return (
        <div id="our-story-section" className="our-story-wrapper">
            <div className="title txt-size-2rem font-a-b ">
                <p>Our Story</p>
            </div>
            <SlickSlider
                data={data}
                settings={settings}
                class={"our-story-slider-wrapper"}/>
        </div>
    )
}

export default OurStory;