import React from 'react';
import _ from "lodash";
import HalfCircle from "./halfCircle";

const GroomSideBySide = (props) => {
    const { data } = props;
    // data.componentClassName = for added classes

    const circleData = {
        color: "orange-bg",
        type: "upper"
    }
    const circleData2 = {
        color: "orange-bg",
        type: "lower"
    }

    return <div id="groom-side-by-side-component" className={_.get(data, "componentClassName", "")} >
        <HalfCircle data={circleData} />
        <div className={"body-wrapper " + data.className}>
            <div className="title-wrapper">
                <div className="title">{data.title}</div>
                <div className="description">{data.description}</div>
            </div>
            <div className="groom-wrapper">
                {
                    _.get(data, "groom", []).map((item, idx) => {
                        return <div className="item" key={idx}>
                            <img src={item.photo} alt="groom" />
                            <h1 className="nickname">{item.nickname}</h1>
                            <div className="name">{item.name}</div>
                            <div className="divider-wrapper">
                                <div className="divider-container">
                                    <div className="divider" />
                                </div>
                            </div>
                            {
                                idx === 0
                                    ? <strong>The Son of</strong>
                                    : <strong>The Daughter of</strong>
                            }
                            <div className="description">{item.description}</div>
                            <a href={item.instagramLink}>IG</a>
                        </div>
                    })
                }
            </div>
        </div>
        <HalfCircle data={circleData2} />
    </div>
}

export default GroomSideBySide;