import React from 'react'
import {
    home,
    couple,
    calender,
    gallery,
    quote,
    wish,

    homeGrey,
    coupleGrey,
    calenderGrey,
    galleryGrey,
    quoteGrey,
    wishGrey,
    bibleGrey,
} from '../asset'

const MenuSecond = (props) => {
    const { onChangeRef } = props
    
    return (
        <div className="menu-sticky">
            <div className="container-menu txt-size-075rem font-m-r white-txt">
                <div onClick={() => onChangeRef("home")}>
                    <img src={homeGrey} alt="Index" loading="lazy" />
                    <div>HOME</div>
                </div>
                <div onClick={() => onChangeRef("couple")}>
                    <img src={coupleGrey} alt="Index" loading="lazy" />
                    <div>COUPLE</div>
                </div>
                <div onClick={() => onChangeRef("event")}>
                    <img src={calenderGrey} alt="Index" loading="lazy" />
                    <div>EVENT</div>
                </div>
                <div onClick={() => onChangeRef("gallery")}>
                    <img src={galleryGrey} alt="Index" loading="lazy" />
                    <div>GALLERY</div>
                </div>
                <div onClick={() => onChangeRef("quote")}>
                    <img src={quoteGrey} alt="Index" loading="lazy" />
                    <div>Quote</div>
                </div>
                <div onClick={() => onChangeRef("wish")}>
                    <img src={wishGrey} alt="Index" loading="lazy" />
                    <div>WISH</div>
                </div>
            </div>
        </div>
    )
}

export default MenuSecond
