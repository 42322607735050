import * as types from "../types";

export const setHomeLoading = (property, value) => {
    return {
        type: types.ULTIMATE_SET_LOADER,
        property, value
    }
}

export const fetchWishes = (property, value) => {
    return {
        type: types.ULTIMATE_GET_WISH,
        property, value
    }
}

export const postWish = (data) => {
    return {
        type: types.ULTIMATE_POST_WISH,
        data
    }
}

export const postWishClient = (data) => {
    return{
        type: types.ULTIMATE_POST_WISH_CLIENT,
        data
    }
}

export const fetchWishesClient = (id) => {
    return {
        type: types.ULTIMATE_GET_WISH_CLIENT,
        id
    }
}

export const fetchWishesByID = (id) => {
    return {
        type: types.ULTIMATE_GET_WISH_BY_ID,
        id
    }
}