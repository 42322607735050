//Image
import foto1 from './images/1.webp'
import foto2 from './images/2.webp'
import foto3 from './images/3.webp'
import foto4 from './images/4.webp'
import foto5 from './images/5.webp'

import rekening from './images/rekening.jpg'
import qrcode1 from './images/qrcode1.jpg'
import qrcode2 from './images/qrcode2.jpg'

import logoWhite from './images/weboosh-white.png'
import logoEinvitation from './images/logo-transparant.png'

import coklat1 from './images/coklat-1.png'
import coklat2 from './images/coklat-2.png'
import coklatMuda1 from './images/coklat-muda-1.png'
import coklatMuda2 from './images/coklat-muda-2.png'

import purple1 from './images/purple1.png'
import purple2 from './images/purple2.png'
import purpleMuda1 from './images/purplemuda1.png'
import purpleMuda2 from './images/purplemuda2.png'

import abel1 from './images/purple1/abel1.png'
import abel2 from './images/purple1/abel2.png'
import abelmuda1 from './images/purple1/abelmuda1.png'
import abelmuda2 from './images/purple1/abelmuda2.png'

import abel21 from './images/purple2/abel21.png'
import abel22 from './images/purple2/abel22.png'
import abel2muda1 from './images/purple2/abel2muda1.png'
import abel2muda2 from './images/purple2/abel2muda2.png'

import olin1 from './images/olin/coklat-1.png'
import olin2 from './images/olin/coklat-2.png'
import olinmuda1 from './images/olin/coklat-muda-1.png'
import olinmuda2 from './images/olin/coklat-muda-2.png'

import elvia1 from './images/elvia/coklat-1.png'
import elvia2 from './images/elvia/coklat-2.png'
import elviamuda1 from './images/elvia/coklat-muda-1.png'
import elviamuda2 from './images/elvia/coklat-muda-2.png'

import jingga1 from './images/jingga/coklat-1.png'
import jingga2 from './images/jingga/coklat-2.png'
import jinggamuda1 from './images/jingga/coklat-muda-1.png'
import jinggamuda2 from './images/jingga/coklat-muda-2.png'

import platinum1 from './images/foto-1.webp'
import platinum2 from './images/foto-2.webp'
import platinum3 from './images/foto-3.webp'
import platinum4 from './images/foto-4.webp'
import platinum5 from './images/foto-5.webp'
import ilustrasi1 from './images/ilustrasi-1.png'
import ilustrasi2 from './images/ilustrasi-2.png'

import ginia1 from './images/ginia/1.jpg'
import ginia2 from './images/ginia/2.jpg'
import ginia3 from './images/ginia/3.jpg'
import ginia4 from './images/ginia/4.jpg'
import ginia5 from './images/ginia/5.jpg'
import ginia6 from './images/ginia/6.jpg'
import ginia7 from './images/ginia/7.jpg'
import ginia8 from './images/ginia/8.jpg'
import ginia9 from './images/ginia/9.jpg'
import ginia10 from './images/ginia/10.jpg'
import ginia11 from './images/ginia/11.jpg'
import ginia12 from './images/ginia/12.jpg'
import ginia13 from './images/ginia/13.jpg'
import ginia14 from './images/ginia/14.jpg'
import ginia15 from './images/ginia/15.jpg'

import jo1 from './images/jonathan/1.jpg'
import jo2 from './images/jonathan/2.jpg'
import jo3 from './images/jonathan/3.jpg'
import jo4 from './images/jonathan/4.jpg'
import jo5 from './images/jonathan/5.jpg'
import jo6 from './images/jonathan/5.jpg'
import jo7 from './images/jonathan/5.jpg'
import qr1 from './images/jonathan/qr1.jpg'
import qr2 from './images/jonathan/qr2.jpg'

//Icon
import home from './images/icon-05.png'
import couple from './images/icon-02.png'
import calender from './images/icon-04.png'
import gallery from './images/icon-03.png'
import quote from './images/icon-08.png'
import bible from './images/icon-09.png'
import wish from './images/icon-06.png'

import homeGrey from './images/icon/grey/icon-01.png'
import coupleGrey from './images/icon/grey/icon-02.png'
import calenderGrey from './images/icon/grey/icon-03.png'
import galleryGrey from './images/icon/grey/icon-04.png'
import quoteGrey from './images/icon/grey/icon-05.png'
import wishGrey from './images/icon/grey/icon-06.png'
import bibleGrey from './images/icon/grey/icon-07.png'

//Music
import believeinyou from './musics/believeinyou.mp3'
import marryyourdaughter from './musics/marry-your-daughter.mp3'
import silence from './musics/silence.mp3'
import sezairi from './musics/sezairi.mp3'
import thegift from './musics/thegift.mp3'
import foreverlikethat from './musics/foreverlikethat.mp3'
import theone from './musics/kodaline_the_one.mp3'
import kisahabadi from './musics/kisahabadi.mp3'
import ichooseyou from './musics/ichooseyou.mp3'
import niki from './musics/niki.mp3'

//Protocol
import protocol1 from './images/protocol/protocol1.webp'
import protocol2 from './images/protocol/protocol2.webp'
import protocol3 from './images/protocol/protocol3.webp'
import protocol4 from './images/protocol/protocol4.webp'
import protocol5 from './images/protocol/protocol5.webp'

export {
    foto1,
    foto2,
    foto3,
    foto4,
    foto5,
    rekening,
    qrcode1,
    qrcode2,
    
    believeinyou,
    marryyourdaughter,
    silence,
    sezairi,
    thegift,
    foreverlikethat,
    theone,
    kisahabadi,
    ichooseyou,
    niki,

    logoWhite,
    logoEinvitation,

    coklat1,
    coklatMuda1,
    coklat2,
    coklatMuda2,
    purple1,
    purple2,
    purpleMuda1,
    purpleMuda2,

    abel1, abel2, abelmuda1, abelmuda2, abel21, abel22, abel2muda1, abel2muda2,

    olin1, olin2, olinmuda1, olinmuda2, 

    elvia1, elvia2, elviamuda1, elviamuda2,

    jingga1, jingga2, jinggamuda1, jinggamuda2,

    ginia1,
    ginia2,
    ginia3,
    ginia4,
    ginia5,
    ginia6,
    ginia7,
    ginia8,
    ginia9,
    ginia10,
    ginia11,
    ginia12,
    ginia13,
    ginia14,
    ginia15,

    jo1,
    jo2,
    jo3,
    jo4,
    jo5,
    jo6,
    jo7,
    qr1,
    qr2,

    platinum1,
    platinum2,
    platinum3,
    platinum4,
    platinum5,
    ilustrasi1,
    ilustrasi2,

    home,
    couple,
    gallery,
    calender,
    wish,
    quote,
    bible,

    homeGrey,
    coupleGrey,
    calenderGrey,
    galleryGrey,
    quoteGrey,
    wishGrey,
    bibleGrey,

    protocol1, protocol2, protocol3, protocol4, protocol5
}