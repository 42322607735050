import React from 'react';

const TwoSidedImages = (props) => {
    const { data } = props;

    return <div id="two-sided-images-component">
        <div className={"body-wrapper " + data.className}>
            <div className="item">
                <div className="image-wrapper">
                    <img className="img" alt="img1" src={data.photo} loading="lazy"/>
                </div>
            </div>
            <div className="item">
                <div className="position-relative">
                    <div className="text-wrapper">
                        <p className="text">{data.text}</p>
                        <p className="author"><strong>{data.author}</strong></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default TwoSidedImages;