import React from 'react'
import { Zoom } from 'react-reveal';

import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/scss/lightgallery.scss';
import 'lightgallery/scss/lg-zoom.scss';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

{/* How to use Component
    <Gallery
        data={data}
        altName={"string"}
    /> 
*/}

const GallerySimple = (props) => {
    const {data, altName} = props
    return (
        <div className="container-gallery">
            <Zoom>
                <div className="container-text-video txt-size-2rem font-a-b">
                    Gallery
                </div>
            </Zoom>
            {/* <Zoom> */}
                {/* <p className="txt-size-2rem font-a-b">Gallery</p> */}
                <LightGallery
                    // onInit={onInit}
                    speed={500}
                    plugins={[lgThumbnail, lgZoom]}
                    elementClassNames="lg-custom-thumbnails"
                >
                    <Zoom>
                        <a className="gallery-item gallery-item--1" href={data.gallery.img6}>
                            <img className="gallery-img" alt={altName} src={data.gallery.img6} data-src={data.gallery.img6} loading="lazy" />
                        </a>
                    </Zoom>
                    <Zoom>
                        <a className="gallery-item gallery-item--2" href={data.gallery.img2}>
                            <img className="gallery-img" alt={altName} src={data.gallery.img2} data-src={data.gallery.img2} loading="lazy" />
                        </a>
                    </Zoom>
                    <Zoom>
                        <a className="gallery-item gallery-item--3" href={data.gallery.img1}>
                            <img className="gallery-img" alt={altName} src={data.gallery.img1} data-src={data.gallery.img1} loading="lazy" />
                        </a>
                    </Zoom>
                    <Zoom>
                        <a className="gallery-item gallery-item--4" href={data.gallery.img4}>
                            <img className="gallery-img" alt={altName} src={data.gallery.img4} data-src={data.gallery.img4} loading="lazy" />
                        </a>
                    </Zoom>
                    <Zoom>
                        <a className="gallery-item gallery-item--5" href={data.gallery.img5}>
                            <img className="gallery-img" alt={altName} src={data.gallery.img5} data-src={data.gallery.img5} loading="lazy" />
                        </a>
                    </Zoom>
                    <Zoom>
                        <a className="gallery-item gallery-item--6" href={data.gallery.img10}>
                            <img className="gallery-img" alt={altName} src={data.gallery.img10} data-src={data.gallery.img10} loading="lazy" />
                        </a>
                    </Zoom>
                    <Zoom>
                        <a className="gallery-item gallery-item--7" href={data.gallery.img9}>
                            <img className="gallery-img" alt={altName} src={data.gallery.img9} data-src={data.gallery.img9} loading="lazy" />
                        </a>
                    </Zoom>
                    <Zoom>
                        <a className="gallery-item gallery-item--8" href={data.gallery.img8}>
                            <img className="gallery-img" alt={altName} src={data.gallery.img8} data-src={data.gallery.img8} loading="lazy" />
                        </a>
                    </Zoom>
                    
                    <Zoom>
                        <a className="gallery-item gallery-item--9" href={data.gallery.img7}>
                            <img className="gallery-img" alt={altName} src={data.gallery.img7} data-src={data.gallery.img7} loading="lazy" />
                        </a>
                    </Zoom>

                    <Zoom>
                        <a className="gallery-item gallery-item--10" href={data.gallery.img13}>
                            <img className="gallery-img" alt={altName} src={data.gallery.img13} data-src={data.gallery.img13} loading="lazy" />
                        </a>
                    </Zoom>

                    <Zoom>
                        <a className="gallery-item gallery-item--11" href={data.gallery.img3}>
                            <img className="gallery-img" alt={altName} src={data.gallery.img3} data-src={data.gallery.img3} loading="lazy" />
                        </a>
                    </Zoom>
                    
                </LightGallery>
            {/* </Zoom> */}
        </div>
    )
}

export default GallerySimple
