import * as types from "../types";

const initState = {
    isLoading: false,
    wishesList: [],

}

export default function (state = initState, action) {
    switch (action.type) {
        case types.ULTIMATE_SET_LOADER: {
            return {
                ...state,
                isLoading: action.value
            }
        }
        case types.ULTIMATE_GET_WISH_SUCCESS: {
            return {
                ...state,
                [action.property]: action.value
            }
        }
        case types.ULTIMATE_POST_WISH_SUCCESS: {
            return {
                ...state,
                [action.property]: action.value
            }
        }
        case types.ULTIMATE_GET_WISH_BY_ID_SUCCESS: {
            return {
                ...state,
                [action.property]: action.value
            }
        }
        default: {
            return { ...state }
        }
    }
}