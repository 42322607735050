import React from 'react';
import _ from "lodash";
import HalfCircle from "./halfCircle";

const DateAndPlaceCircle = (props) => {
    const { data } = props;
    const circleData = {
        color: "white-bg",
        type: "upper"
    }
    const circleData2 = {
        color: "white-bg",
        type: "lower"
    }

    return <div id="data-and-place-circle-component" className={_.get(data, "componentClassName", "")}>
        <HalfCircle data={circleData} />
        <h2 className="title">{data.title}</h2>
        <p className="sub-title">{data.subTitle}</p>

        <div className="event-wrapper">
            {
                _.get(data, "events", []).map((item, idx) => {
                    return <div className="event" key={idx}>
                        <h2 className="title">{item.title}</h2>
                        <div className="date">{item.date}</div>
                        <div className="time">{item.time}</div>
                    </div>
                })
            }
        </div>
        <HalfCircle data={circleData2} />
    </div>
}

export default DateAndPlaceCircle;