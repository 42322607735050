import React from 'react';
import _ from "lodash";

const HalfCircle = (props) => {
    let { data, backgroundColor } = props;
    // type = lower | upper

    return <div className={"half-circle type-" + _.get(data, "type", "") }>
        <div
            className={"body-wrapper " + _.get(data, "color", "")}
            style={{ background: backgroundColor }}
        />
    </div>
}

export default HalfCircle;