import React, { useEffect, useState, createRef, Fragment, useRef} from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../redux/Home/action'
import { Fade, Zoom } from 'react-reveal';
import HeadShake from 'react-reveal/HeadShake';
import AudioPlayer from '../../../player/music/music.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMusic, faVolumeMute } from '@fortawesome/free-solid-svg-icons'
// import Navbar from '../../components/layout/Navbar'
// import Carousel from '../../components/carousel/carousel'

import {
    jo1, jo2, jo3, jo4, jo5, jo6, jo7, foto1, sezairi, qr1, qr2
} from '../../../../asset'

import Splash from '../../../splashSimple'
import Gift from '../../../giftSimple'
import Footer from '../../../layout/Footer/ultimate/ultimate'

const Basic = (props) => {
    const [openInv, setOpenInv] = useState(false)
    
    const container = createRef();
    const [audioSrc, setAudioSrc] = useState('')
    const [playing, setPlaying] = useState(false);
    const [openModal, setOpenModal] = useState(false)
    const content = useRef(null)

    const dataBasic = {
        imageOne: foto1,
        useAudio: false,
        firstColor: "#FFEDED",
        secondColor: "#B3541E",
        thirdColor: "#362222",
        opening: "The Wedding of",
        quoteOne: "Together with their families",
        quoteTwo: "We're getting hitched! And we'd be delighted if you could join our virtual wedding!",
        descriptionOne: "Request the honor of your presence on our Holy Matrimony",
        matrimonyVenue: "Gereja Hati Santa Perawan Maria Tak Bernoda",
        matrimonyDetail: "Jl. Raya Daan Mogot No.14, RT.005/RW.005, Sukarasa, Kec. Tangerang, Kota Tangerang, Banten 15111",
        matrimonyTime: "10.00 AM",
        matrimonyDate: "22 January 2022",

        weddingVenue: "JHL Solitaire Hotel, Restaurant Royal 8",
        weddingDetail: "Jl. Gading Serpong Boulevard, Curug Sangereng, Kec. Klp. Dua, Kabupaten Tangerang, Banten 15810",
        weddingTime: "18.00 PM",
        weddingDate: "22 January 2022",
    
        linkLive: "https://www.youtube.com/embed/KM1jkox1QSI",
        man: {
            name: "Jonathan Tandarma Tjhin",
            status: "Groom",
        },
        woman: {
            name: "Marchella",
            status: "Bride",
        },
        carousel: [
            jo4,
            jo1,
            jo3,
        ],
        account:{
            "qrCode1": qr1,
            "qrCode2": qr2,
            "rekening1": "1084541004",
            "rekening2": "7130986581"
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0) //Auto scroll top setiap buka
        setAudioSrc(sezairi)
        setPlaying(true)
    }, []);

    useEffect(() => {
        setPlaying(true)
        if (content.current) {
            content.current.scrollIntoView({
                behavior: 'smooth'
            });
        }
    }, [openInv])

    // useEffect(() => {
    //     if (window && 'IntersectionObserver' in window) {
    //         if (music && music.current) {
    //             // musicObserver.observe(music.current);
    //         }
    //     } else {
    //         setAudioSrc(believeinyou)
    //     }
    // }, [music])

    return (
        <div id="basic-page">
            <Splash
                onOpen={setOpenInv}
                guest={""}
                // hashtag={"#BEcomeOne #BEinLove"}
                coupleName={"Jonathan Tandarma Tjhin"}
                coupleName2={"Marchella"}
                fullDate={dataBasic.venueDate}
                day={22}
                month={1}
                srcImg={jo2}
            />
            {
                openInv ?
                    <Fragment>
                        <div ref={content} className="banner">
                            <img
                                className="image"
                                src={jo5}
                                loading="lazy"
                                alt="image-one"
                            />
                        </div>

                        <div style={{ backgroundColor: dataBasic.firstColor, padding: '1px 0 1px 0' }}>
                            <div className="couple">
                                <div className="bride-groom font-ar">
                                    <Fade left>
                                        <h1 className='font-ar' style={{ color: dataBasic.thirdColor }}>{dataBasic.man.name}</h1>
                                        <section>
                                            <h5 className="font-m-r" style={{ color: dataBasic.thirdColor }}>{dataBasic.man.status}</h5>
                                        </section>
                                    </Fade>
                                </div>
                                <Zoom><h1 className="sign font-a-b" style={{ color: dataBasic.thirdColor }}>&</h1></Zoom>
                                <div className="bride-groom font-ar">
                                    <Fade right>
                                        <h1 className='font-ar' style={{ color: dataBasic.thirdColor }}>{dataBasic.woman.name}</h1>
                                        <section>
                                            <h5 className="font-m-r" style={{ color: dataBasic.thirdColor }}>{dataBasic.woman.status}</h5>
                                        </section>
                                    </Fade>
                                </div>
                            </div>
                            <div className="container-content">
                                <Fade top>
                                    <h5 className='font-m-b' style={{ color: dataBasic.thirdColor }}>Holy Matrimony</h5>
                                    <h5 className="font-m-r" style={{ color: dataBasic.thirdColor }}>{dataBasic.matrimonyVenue}</h5>
                                    {/* <h5 className="font-m-r" style={{ color: dataBasic.thirdColor }}>{dataBasic.matrimonyDetail}</h5> */}
                                    <h5 className="font-m-r" style={{ color: dataBasic.thirdColor }}>{dataBasic.matrimonyDate}</h5>
                                    <section>
                                        <h5 className="font-m-r" style={{ color: dataBasic.thirdColor }}>{dataBasic.matrimonyTime}</h5>
                                    </section>
                                </Fade>
                            </div>

                            <div className="container-content">
                                <Fade top>
                                    <h5 className='font-m-b' style={{ color: dataBasic.thirdColor }}>The Wedding Day</h5>
                                    <h5 className="font-m-r" style={{ color: dataBasic.thirdColor }}>{dataBasic.weddingVenue}</h5>
                                    {/* <h5 className="font-m-r" style={{ color: dataBasic.thirdColor }}>{dataBasic.weddingDetail}</h5> */}
                                    <h5 className="font-m-r" style={{ color: dataBasic.thirdColor }}>{dataBasic.weddingDate}</h5>
                                    <section>
                                        <h5 className="font-m-r" style={{ color: dataBasic.thirdColor }}>{dataBasic.weddingTime}</h5>
                                    </section>
                                </Fade>
                            </div>
                        </div>
                        <Zoom>
                            <div className="image-content-container">
                                {
                                    dataBasic?.carousel.map((item, index) => {
                                        return <div className="image-content" key={index}>
                                            <img
                                                className="image"
                                                src={item}
                                                loading="lazy"
                                                alt={"carousel-" + index}
                                            />
                                        </div>
                                    })
                                }
                            </div>
                        </Zoom>

                        <div style={{ backgroundColor: dataBasic.firstColor, padding: '1px 0 1px 0', display: "flex", flexDirection: "column" }}>
                            <div style={{ textAlign: 'center', margin: '3rem 0 0 0'}}>
                                <Fade top><h1 className='font-a-b' style={{ color: dataBasic.thirdColor }}>Registration</h1></Fade>
                            </div>
                            <div className="container-content font-a-b">
                                <Fade top><h3 style={{ color: dataBasic.thirdColor }}>Holy Matrimony</h3></Fade>
                            </div>
                            <div className='btn-register'>
                                <a href='https://tinyurl.com/DaftarHadirPerkawinan' target='_blank' className="btn-register-style txt-size-1rem">Click to Register</a>
                            </div>

                            <div className="container-content font-a-b">
                                <Fade top><h3 style={{ color: dataBasic.thirdColor }}>Wedding Day</h3></Fade>
                            </div>
                            <div className='btn-register'>
                                <a href='https://forms.gle/gm7R34VbcQVxV4X2A' target='_blank' className="btn-register-style txt-size-1rem">Click to Register</a>
                            </div>

                            <br /><br />                       
                        </div>
                        <br />
                        <Gift
                            openModal={openModal}
                            setOpenModal={setOpenModal}
                            data={dataBasic.account}
                        />

                        <div className="container-quote font-m-m">
                            <HeadShake>
                                <h3 style={{ color: dataBasic.thirdColor }}>Thank You</h3>
                            </HeadShake>
                        </div>

                        <Footer />

                        <a id="myButton" className="music" onClick={() => setPlaying(!playing)}>
                            {
                                playing ?
                                    <Fragment>
                                        <div className="font-m-r" style={{ display: 'none' }}>
                                            <AudioPlayer src={sezairi} onPlay={playing} />
                                        </div>
                                        <FontAwesomeIcon icon={faVolumeMute} className="my-music" />
                                    </Fragment>
                                    :
                                    <FontAwesomeIcon icon={faMusic} className="my-music" />
                            }
                        </a>
                    </Fragment>
                : null
            }
        </div>
    )
}

const mapStateToProps = (state) => ({
    Home: state.Home
})
const mapDispatchToProps = {
    ...actions
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Basic);