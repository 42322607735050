import React, { useState, useEffect } from 'react'
import { Fade, Zoom } from 'react-reveal';
import {
    platinum4,
} from '../../../asset'

const Splash = (props) => {
    const [timeLeft, setTimeLeft] = useState('');
    const { onOpen, guest } = props

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
            // setYear(new Date().getFullYear());
        }, 1000);
        // Clear timeout if the component is unmounted
        return () => clearTimeout(timer);
    });

    const timerComponents = [];
    Object.keys(timeLeft).forEach((interval, index) => {
        if (!timeLeft[interval]) {
            return;
        }
        timerComponents.push(
            <div key={index}>
                <div className="interval-date font-m-r txt-size-1rem">
                    {timeLeft[interval]}
                </div>
                <div>
                    {interval}
                </div>
            </div>
        );
    });

    const calculateTimeLeft = () => {
        let year = new Date().getFullYear();
        const difference = +new Date(`12/28/${year}`) - +new Date();
        let timeLeft = {};
        if (difference > 0) {
            timeLeft = {
                // Days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                // Hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                // Minutes: Math.floor((difference / 1000 / 60) % 60),
                // Seconds: Math.floor((difference / 1000) % 60)
                Days: 8,
                Hours: 8,
                Minutes: 8,
                Seconds: 8
            };
        }
        return timeLeft;
    };

    const onChange = () => {
        onOpen(true)
    }

    return (
        <div>
            <div className="container-header">
                <div className="container-image">
                    <img
                        className="image-header"
                        src={platinum4}
                        loading="lazy"
                        alt="cipto and mawar"
                    />
                    <div className="container-text">
                        <Fade left><div className="text-header font-m-b txt-size-1rem">The Wedding of</div></Fade>
                        <Zoom><div className="text-header font-ar txt-size-35rem" style={{ lineHeight: '70px' }}>Cipto & Mawar</div></Zoom>
                        <Fade right>
                            <div className="text-header font-m-r txt-size-1rem">
                                Dear
                                <strong className="font-m-b">
                                    {
                                        guest ? ` ${guest},` : " Guest,"
                                    }
                                </strong>
                            </div>
                        </Fade>
                        <Fade right><div className="text-header font-m-r txt-size-1rem">We request the honor of your presence to join on our virtual wedding!</div></Fade>
                        <Zoom><div className="button-open" onClick={() => onChange()}>Open Invitation</div></Zoom>
                    </div>
                    <div className="container-date">
                        <Fade bottom><div className="text-header font-m-r txt-size-1rem">July 1, 2021</div></Fade>
                    </div>
                    <div className="container-date-countdown">
                        {timerComponents.length ? timerComponents : null}
                        {/* <div>5 days</div>
                        <div>4 hours</div>
                        <div>3 minutes</div>
                        <div>2 seconds</div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Splash
