import React, { lazy, useEffect, useState, Suspense, useRef } from 'react'
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../../redux/Ultimate/action'

import data from '../../../data/jingga.json'
import AudioPlayer from '../../player/music/music'
import { Fade, Zoom } from 'react-reveal';
import HeadShake from 'react-reveal/HeadShake';
import {
    jingga1,
    jingga2,
    jinggamuda1,
    jinggamuda2,

    platinum1,
    platinum2,
    platinum3,
    platinum5,
    ilustrasi1,
    ilustrasi2,

    home,
    couple,
    gallery,
    calender,
    wish,
    quote,
    bible,
    theone,
} from '../../../asset'
import { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt, faMap, faPaperPlane, faMusic, faVolumeMute } from '@fortawesome/free-solid-svg-icons'
import { InstagramOutlined } from '@ant-design/icons'

import LightGallery from 'lightgallery/react';

// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

// If you want you can use SCSS instead of css
import 'lightgallery/scss/lightgallery.scss';
import 'lightgallery/scss/lg-zoom.scss';

// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

// import Splash from './splash.js'
import Splash from '../../splashSimple'
import OurStory from '../../SlickSlider/OurStory'
import Meta from "../../Meta"
const Footer = lazy(() => import('../../layout/Footer/ultimate/ultimate'))

const Ultimate = (props) => {
    const { Ultimate, fetchWishesClient, postWishClient, fetchWishes, postWish } = props
    let { isLoading, wishesList } = Ultimate

    let { guest } = useParams();

    const content = useRef(null)
    const homeRef = useRef(null)
    const coupleRef = useRef(null)
    const eventRef = useRef(null)
    const galleryRef = useRef(null)
    const quoteRef = useRef(null)
    const wishRef = useRef(null)

    const [openInv, setOpenInv] = useState(false)
    const [audioSrc, setAudioSrc] = useState('')
    const [playing, setPlaying] = useState(false);
    const [name, setName] = useState("")
    const [wishes, setWishes] = useState("")
    const [openModal, setOpenModal] = useState(false)

    // if (openModal) {
    //     document.body.style.overflow = 'hidden';
    // } else {
    //     document.body.style.overflow = 'unset';
    // }

    useEffect(() => {
        window.scrollTo(0, 0) //Auto scroll top setiap buka
        setAudioSrc(theone)
        setPlaying(true)
        // fetchWishes()
        fetchWishesClient(7)
    }, [])

    useEffect(() => {
        if (content.current) {
            setPlaying(true)
            content.current.scrollIntoView({
                behavior: 'smooth'
            });
        }
    }, [openInv])

    const onChangeRef = (ref) => {
        switch (ref) {
            case "home":
                if (homeRef.current) {
                    homeRef.current.scrollIntoView({
                        behavior: 'smooth'
                    });
                }
                break;
            case "couple":
                if (coupleRef.current) {
                    coupleRef.current.scrollIntoView({
                        behavior: 'smooth'
                    });
                }
                break;
            case "event":
                if (eventRef.current) {
                    eventRef.current.scrollIntoView({
                        behavior: 'smooth'
                    });
                }
                break;
            case "gallery":
                if (galleryRef.current) {
                    galleryRef.current.scrollIntoView({
                        behavior: 'smooth'
                    });
                }
                break;
            case "quote":
                if (quoteRef.current) {
                    quoteRef.current.scrollIntoView({
                        behavior: 'smooth'
                    });
                }
                break;
            case "wish":
                if (wishRef.current) {
                    wishRef.current.scrollIntoView({
                        behavior: 'smooth'
                    });
                }
                break;
            default:
                break;
        }
    }

    const onInit = () => {
        console.log('E-Invitation by Weboosh has been initialized');
    };

    const onSubmit = (e) => {
        e.preventDefault()
        if (name !== "" && wishes !== "") {
            let data = {
                id: 7,
                name: name,
                wishes: wishes
            }
            postWishClient(data)
            // postWish(data)
            setName("")
            setWishes("")
        } else {
            alert("Please Fill Your Name and Wishes First")
        }
    }

    const Popup = () => {

        return (
            <div className='popup'>
                <span className="close" onClick={() => setOpenModal(!openModal)}>&times;</span>
                <div className='popup_inner'>
                    <p className="txt-size-2rem font-a-b">Digital Gift</p>
                    <p className="font-m-r">Your kind blessing can be sent to this information bellow</p>

                    {/* <div className="popup-img">
                        <img
                            className="popup-img-content"
                            src={data.account.qrCode1}
                            alt="Rekening"
                        />
                    </div> */}
                    <p className="font-m-r">BCA 8832628882</p>
                    <p className="font-m-r">a/n Dicky Budiman </p>
                    <button className="btn-popup" onClick={() => {
                        navigator.clipboard.writeText(data.account.rekening1)
                        alert(`Copied to clipboard: ${data.account.rekening1}`)
                    }}>
                        Copy to Clipboard
                    </button>
                    {/* <br /><br />
                    <div className="popup-img">
                        <img
                            className="popup-img-content"
                            src={data.account.qrCode2}
                            alt="Rekening"
                        />
                    </div>
                    <button className="btn-popup" onClick={() => {
                        navigator.clipboard.writeText(data.account.rekening2)
                        alert(`Copied to clipboard: ${data.account.rekening2}`)
                    }}>
                        Copy to Clipboard
                    </button> */}
                </div>
            </div>
        )
    }

    const MetaData = () => {
        return(
            <Meta
                metaTitle={data.couple.fullName}
                description={"The Wedding of " + data.couple.fullName}
                keywords={"The Wedding of " + data.couple.fullName}
                image={data.gallery.img9}
                url="https://einvitation.weboosh.id/dickyjingga"
            />
        )
    }

    return (
        <div id="ultimate-page">
            <MetaData />
            <div ref={homeRef} className="container-body type-blue-60 type-grey-11">
                <Splash
                    onOpen={setOpenInv}
                    guest={guest}
                    coupleName={data.couple.fullName}
                    fullDate={data.moment.fullDate}
                    day={18}
                    month={12}
                    srcImg={data.gallery.img5}
                />
                {
                    openInv ?
                        <Fragment>
                            <div ref={content} className="content">
                                <div className="container-banners bg-banner">
                                    {/* <div className="container-image-banner">
                                        <img
                                            className="image-banner"
                                            src={jingga1}
                                            loading="lazy"
                                            alt="einvitation"
                                        />
                                    </div> */}
                                    <div className="container-content-banner">
                                        <div className="container-icon-banner">
                                            <Zoom>
                                                <img
                                                    className="icon-banner"
                                                    src={ilustrasi1}
                                                    loading="lazy"
                                                    alt="icon"
                                                />
                                            </Zoom>
                                        </div>
                                        <Zoom>
                                            <div className="text-banner">
                                                <p className="txt-size-1rem font-m-r">{data.verse.one}</p>
                                                <p className="txt-size-075rem font-m-m">{data.verse.oneDesc}</p>
                                            </div>
                                        </Zoom>
                                        <div className="container-image2-banner">
                                            <img
                                                className="image-banner"
                                                src={jinggamuda1}
                                                loading="lazy"
                                                alt="einvitation"
                                            />
                                        </div>
                                        <div className="container-image3-banner">
                                            <Fade>
                                                <img
                                                    className="image3-banner"
                                                    src={data.gallery.img4}
                                                    loading="lazy"
                                                    alt="einvitation"
                                                />
                                            </Fade>
                                        </div>
                                    </div>
                                </div>

                                <div ref={coupleRef} className="container-bride">
                                    <div className="container-icon-bride">
                                        <Zoom>
                                            <img
                                                className="icon-bride"
                                                src={ilustrasi2}
                                                loading="lazy"
                                                alt="icon"
                                            />
                                        </Zoom>
                                    </div>
                                    <div className="title-bride">
                                        <Fade bottom>
                                            <div className="txt-size-2rem font-a-b">The Groom & Bride</div>
                                        </Fade>
                                    </div>
                                    <Zoom>
                                        <div className="container-bride-name">
                                            <div className="font-ar txt-size-2rem">{data.couple.groomName}</div>
                                            <a target="_blank" rel="noopener noreferrer" href={data.couple.groomUrlIg}>
                                                <div className="row justify-content-center align-items-center font-m-r">
                                                    <InstagramOutlined style={{ fontSize: '15px', padding: '15px 4px' }} /> {data.couple.groomUsernameIg}
                                                </div>
                                            </a>
                                            <div className="font-m-r">1st child of<br/>{data.couple.groomParents}</div>
                                        </div>
                                    </Zoom>
                                    <div className="container-bride-photo">
                                        <Fade left>
                                            <img
                                                className="bride-photo"
                                                src={data.gallery.img10}
                                                loading="lazy"
                                                alt="einvitation"
                                            />
                                        </Fade>
                                    </div>
                                    <br /><br />
                                    <div className="container-bride-photo">
                                        <Fade right>
                                            <img
                                                className="bride-photo"
                                                src={data.gallery.img12}
                                                loading="lazy"
                                                alt="einvitation"
                                            />
                                        </Fade>
                                    </div>
                                    <Zoom>
                                        <div className="container-bride-name">
                                            <div className="font-ar txt-size-2rem">{data.couple.brideName}</div>
                                            <a target="_blank" rel="noopener noreferrer" href={data.couple.brideUrlIg}>
                                                <div className="row justify-content-center align-items-center font-m-r">
                                                    <InstagramOutlined style={{ fontSize: '15px', padding: '15px 4px' }} /> {data.couple.brideUsernameIg}
                                                </div>
                                            </a>
                                            <div className="font-m-r">{data.couple.brideParents}</div>
                                        </div>
                                    </Zoom>
                                </div>

                                <br /><br />
                                <div ref={eventRef} className="container-event">
                                    <Zoom>
                                        <p className="txt-size-2rem font-a-b">Save The Date</p>
                                        <div className="content-event">
                                            <p className="font-m-b">Holy Matrimony</p>
                                            <FontAwesomeIcon icon={faCalendarAlt} />
                                            <p className="font-m-r">{data.moment.fullDate}</p>
                                            <div className="line"></div>
                                            <p className="font-m-r">{data.moment.holyMatrimonyTime}</p>

                                            <FontAwesomeIcon icon={faMap} />
                                            <p className="font-m-r">{data.location.holyMatrimony}</p>
                                            <div className="line"></div>
                                            <p className="font-m-r">{data.location.holyMatrimonyDetails}</p>
                                        </div>
                                    </Zoom>
                                </div>
                                <br />
                                <div className="container-event">
                                    <Zoom>
                                        <div className="content-event">
                                            <p className="font-m-b">The Wedding Day</p>
                                            <FontAwesomeIcon icon={faCalendarAlt} />
                                            <p className="font-m-r">{data.moment.fullDate}</p>
                                            <div className="line"></div>
                                            <p className="font-m-r">{data.moment.receptionTime}</p>

                                            <FontAwesomeIcon icon={faMap} />
                                            <p className="font-m-r">{data.location.reception}</p>
                                            <div className="line"></div>
                                            <p className="font-m-r">{data.location.receptionDetails}</p>
                                        </div>
                                        <br /><br />
                                        <div className="add-google">
                                            <a target="_blank" rel="noopener noreferrer" href={data.moment.holyMatrimonyCalendar}>
                                                ADD TO GOOGLE CALENDAR
                                            </a>
                                        </div>
                                    </Zoom>
                                </div>

                                <br /><br /> <br /><br />
                                <div ref={galleryRef}></div>

                                {/* <div className="container-video">
                                    <iframe
                                        title="Legend and Renata"
                                        className="video"
                                        src="https://www.youtube.com/embed/kH7wlLOQMNM"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen="allowFullScreen">
                                    </iframe>
                                </div> */}
                                {/* <br /><br /> */}
                                <div className="container-gallery">
                                    <Zoom>
                                        <div className="container-text-video txt-size-2rem font-a-b">
                                            Gallery
                                        </div>
                                    </Zoom>
                                    <Zoom>
                                        <p className="txt-size-2rem font-a-b">Gallery</p>
                                        <LightGallery
                                            onInit={onInit}
                                            speed={500}
                                            plugins={[lgThumbnail, lgZoom]}
                                            elementClassNames="lg-custom-thumbnails"
                                        >
                                            <Zoom>
                                                <a className="gallery-item gallery-item--1" href={data.gallery.img1}>
                                                    <img className="gallery-img" alt={data.couple.fullName} src={data.gallery.img1} data-src={data.gallery.img1} loading="lazy" />
                                                </a>
                                            </Zoom>
                                            <Zoom>
                                                <a className="gallery-item gallery-item--2" href={data.gallery.img9}>
                                                    <img className="gallery-img" alt={data.couple.fullName} src={data.gallery.img9} data-src={data.gallery.img9} loading="lazy" />
                                                </a>
                                            </Zoom>
                                            <Zoom>
                                                <a className="gallery-item gallery-item--3" href={data.gallery.img5}>
                                                    <img className="gallery-img" alt={data.couple.fullName} src={data.gallery.img5} data-src={data.gallery.img5} loading="lazy" />
                                                </a>
                                            </Zoom>
                                            <Zoom>
                                                <a className="gallery-item gallery-item--4" href={data.gallery.img3}>
                                                    <img className="gallery-img" alt={data.couple.fullName} src={data.gallery.img3} data-src={data.gallery.img3} loading="lazy" />
                                                </a>
                                            </Zoom>
                                            <Zoom>
                                                <a className="gallery-item gallery-item--5" href={data.gallery.img11}>
                                                    <img className="gallery-img" alt={data.couple.fullName} src={data.gallery.img11} data-src={data.gallery.img11} loading="lazy" />
                                                </a>
                                            </Zoom>
                                            <Zoom>
                                                <a className="gallery-item gallery-item--6" href={data.gallery.img6}>
                                                    <img className="gallery-img" alt={data.couple.fullName} src={data.gallery.img6} data-src={data.gallery.img6} loading="lazy" />
                                                </a>
                                            </Zoom>
                                            <Zoom>
                                                <a className="gallery-item gallery-item--7" href={data.gallery.img8}>
                                                    <img className="gallery-img" alt={data.couple.fullName} src={data.gallery.img8} data-src={data.gallery.img8} loading="lazy" />
                                                </a>
                                            </Zoom>
                                            <Zoom>
                                                <a className="gallery-item gallery-item--8" href={data.gallery.img10}>
                                                    <img className="gallery-img" alt={data.couple.fullName} src={data.gallery.img10} data-src={data.gallery.img10} loading="lazy" />
                                                </a>
                                            </Zoom>

                                            <Zoom>
                                                <a className="gallery-item gallery-item--9" href={data.gallery.img4}>
                                                    <img className="gallery-img" alt={data.couple.fullName} src={data.gallery.img4} data-src={data.gallery.img4} loading="lazy" />
                                                </a>
                                            </Zoom>

                                            <Zoom>
                                                <a className="gallery-item gallery-item--10-el" href={data.gallery.img12}>
                                                    <img className="gallery-img" alt={data.couple.fullName} src={data.gallery.img12} data-src={data.gallery.img12} loading="lazy" />
                                                </a>
                                            </Zoom>

                                            <Zoom>
                                                <a className="gallery-item gallery-item--11-el" href={data.gallery.img2}>
                                                    <img className="gallery-img" alt={data.couple.fullName} src={data.gallery.img2} data-src={data.gallery.img2} loading="lazy" />
                                                </a>
                                            </Zoom>

                                        </LightGallery>
                                    </Zoom>
                                </div>

                                <div ref={quoteRef} className="container-slider">
                                    <Fade>
                                        <div className="container-slider-photo">
                                            <img
                                                className="slide-photo"
                                                src={data.gallery.img1}
                                                loading="lazy"
                                                alt="einvitation"
                                            />
                                        </div>
                                    </Fade>
                                </div>

                                <div className="container-quotes">
                                    <div className="container-image-quotes">
                                        <img
                                            className="image-quotes"
                                            src={jinggamuda2}
                                            loading="lazy"
                                            alt="einvitation"
                                        />
                                    </div>
                                    <Zoom>
                                        <div className="container-text font-a txt-size-2rem" style={{ lineHeight: '30px' }}>
                                            "{data.couple.quotation2}"
                                        </div>
                                        <div className="container-text font-a txt-size-2rem" style={{ lineHeight: '30px' }}>
                                            {data.couple.quotation2desc}
                                        </div>
                                    </Zoom>
                                </div>

                                <br />
                                <Zoom>
                                    {
                                        <OurStory
                                            data={data.story}
                                        />
                                    }
                                </Zoom>

                                <div className="container-gift">
                                    <Zoom>
                                        <p className="txt-size-2rem dark-brown-txt font-a-b">Digital Gift</p>
                                    </Zoom>
                                    <Zoom>
                                        <p className="font-m-r">Your kind blessing can be sent to this information bellow</p>
                                    </Zoom>
                                    <Zoom>
                                        <div className="btn-gift txt-size-1rem">
                                            <button className="btn-gift-style" onClick={() => setOpenModal(!openModal)}>CLICK HERE</button>
                                        </div>
                                    </Zoom>
                                </div>


                                <br /><br />
                                {
                                    openModal ?
                                        <Popup />
                                        : null
                                }
                                <br /><br /><br />
                                <div ref={wishRef} className="container-wishes">
                                    <Zoom>
                                        <p className="txt-size-2rem font-a-b">Wedding Wish</p>
                                        <form onSubmit={(e) => onSubmit(e)}>
                                            <input placeholder="Name" value={name} type="text" maxLength="50" required onChange={(e) => setName(e.target.value)} />
                                            <textarea placeholder="Wedding Wish" value={wishes} type="text" maxLength="500" rows="4" required onChange={(e) => setWishes(e.target.value)} />
                                            <div className="add-wishes">
                                                <button className="btn-wishes-style txt-size-1rem">
                                                    {
                                                        isLoading ? "Sending..." : "SEND WISH"
                                                    }
                                                </button>
                                            </div>
                                        </form>
                                    </Zoom>
                                    <br /><br />
                                    <Zoom>
                                        {
                                            wishesList.length > 0 ?
                                                <div className="container-wishes-list">
                                                    {
                                                        wishesList.map((data, index) => (
                                                            <div key={index}>
                                                                <Fade>
                                                                    <div>
                                                                        <FontAwesomeIcon icon={faPaperPlane} />
                                                                    </div>
                                                                    <div className="content">
                                                                        <p className="name font-m-b txt-size-075rem">{data.name}</p>
                                                                        <p className="font-m-r txt-size-075rem">{data.wishes}</p>
                                                                    </div>
                                                                </Fade>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                                : <div></div>
                                        }
                                    </Zoom>
                                </div>


                                {/* Sampai sini */}
                                <br /><br />
                                <div className="container-thankyou">
                                    <HeadShake>
                                        <div className="container-text-thankyou txt-size-2rem font-a-b">Thank You</div>
                                    </HeadShake>
                                    <Zoom>
                                        <div className="container-text-thankyou font-ar txt-size-35rem" style={{ lineHeight: '70px' }}>{data.couple.fullName}</div>
                                    </Zoom>
                                    <div className="container-image-thankyou">
                                        <img
                                            className="image-thankyou"
                                            src={jingga2}
                                            loading="lazy"
                                            alt="einvitation"
                                        />
                                    </div>
                                </div>
                                {/* Sampai sini */}
                                {/* <div className="font-m-r" style={{ display: 'none' }}>
                                    <AudioPlayer src={audioSrc} onPlay={playing} />
                                </div> */}

                                <a className="music" onClick={() => setPlaying(!playing)}>
                                    {
                                        playing ?
                                            <Fragment>
                                                <div className="font-m-r" style={{ display: 'none' }}>
                                                    <AudioPlayer src={audioSrc} onPlay={playing} />
                                                </div>
                                                <FontAwesomeIcon icon={faMusic} className="my-music" />
                                            </Fragment>
                                            :
                                            <FontAwesomeIcon icon={faVolumeMute} className="my-music" />
                                    }
                                </a>

                                <Suspense fallback={<div>Loading...</div>}>
                                    <Footer />
                                </Suspense>
                                <div className="menu-sticky">
                                    <div className="container-menu txt-size-075rem font-m-r white-txt">
                                        <div onClick={() => onChangeRef("home")}>
                                            <img src={home} alt="Index" loading="lazy" />
                                            <div>HOME</div>
                                        </div>
                                        <div onClick={() => onChangeRef("couple")}>
                                            <img src={couple} alt="Index" loading="lazy" />
                                            <div>COUPLE</div>
                                        </div>
                                        <div onClick={() => onChangeRef("event")}>
                                            <img src={calender} alt="Index" loading="lazy" />
                                            <div>EVENT</div>
                                        </div>
                                        <div onClick={() => onChangeRef("gallery")}>
                                            <img src={gallery} alt="Index" loading="lazy" />
                                            <div>GALLERY</div>
                                        </div>
                                        <div onClick={() => onChangeRef("quote")}>
                                            <img src={bible} alt="Index" loading="lazy" />
                                            <div>VERSE</div>
                                        </div>
                                        <div onClick={() => onChangeRef("wish")}>
                                            <img src={wish} alt="Index" loading="lazy" />
                                            <div>WISH</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </Fragment>
                        : null
                }
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    Ultimate: state.Ultimate
})
const mapDispatchToProps = {
    ...actions
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Ultimate);
