import React from 'react'
import { HeartFilled, InstagramOutlined } from '@ant-design/icons'
import {
    logoWhite
} from '../../../../asset'

const Footer = () => {
    return (
        <div id="footer-section-ultimate">
            <div className="row justify-content-center">
                <div className="font-m-r align-items-center">
                    Made with <span><HeartFilled style={{ color: 'red', fontSize: '25px', padding: '0 5px' }} /></span> by
                </div>
                <div className="container-logo-image">
                    <a href="https://www.einvitation.weboosh.id">
                        <img
                            className="img"
                            src={logoWhite}
                            loading="lazy"
                            alt="logo"
                        />
                    </a>
                </div>
            </div>
            <a href="https://www.instagram.com/weboosh">
                <div className="row justify-content-center align-items-center" style={{ fontSize: '25px', paddingTop: '15px' }}><InstagramOutlined /></div>
            </a>
        </div>
    )
}

export default Footer