import React, { Fragment } from 'react'
import { Fade, Zoom } from 'react-reveal';
import { InstagramOutlined } from '@ant-design/icons'

{/* How to Use Component
    <Couple
        illustrtaion={imgSrc}
        data={data}
        imgGroom={imgSrc}
        imgBride={imgSrc}
    /> 
*/}

const CoupleSimple = (props) => {
    const { illustrtaion, data, imgGroom, imgBride } = props
    return (
        <div className="container-bride">
            <div className="container-icon-bride">
                <Zoom>
                    <img
                        className="icon-bride"
                        src={illustrtaion}
                        loading="lazy"
                        alt="icon"
                    />
                </Zoom>
            </div>
            <div className="title-bride">
                <Fade bottom>
                    <div className="txt-size-2rem font-a-b">The Groom & Bride</div>
                </Fade>
            </div>
            <Zoom>
                <div className="container-bride-name">
                    <div className="font-ar txt-size-2rem">{data.couple.groomName}</div>
                    <a target="_blank" rel="noopener noreferrer" href={data.couple.groomIg}>
                        <div className="row justify-content-center align-items-center" style={{ fontSize: '25px', padding: '15px 0px' }}><InstagramOutlined /></div>
                    </a>
                    <div className="font-m-r">{data.couple.groomParents}</div>
                </div>
            </Zoom>
            <div className="container-bride-photo">
                <Fade left>
                    <img
                        className="bride-photo"
                        src={imgGroom}
                        loading="lazy"
                        alt="einvitation weboosh"
                    />
                </Fade>
            </div>
            <br /><br />
            <div className="container-bride-photo">
                <Fade right>
                    <img
                        className="bride-photo"
                        src={imgBride}
                        loading="lazy"
                        alt="einvitation weboosh"
                    />
                </Fade>
            </div>
            <Zoom>
                <div className="container-bride-name">
                    <div className="font-ar txt-size-2rem">{data.couple.brideName}</div>
                    <a target="_blank" rel="noopener noreferrer" href={data.couple.brideIg}>
                        <div className="row justify-content-center align-items-center" style={{ fontSize: '25px', padding: '15px 0px' }}><InstagramOutlined /></div>
                    </a>
                    <div className="font-m-r">{data.couple.brideParents}</div>
                </div>
            </Zoom>
        </div>
    )
}

export default CoupleSimple
