import { all, takeLatest, call, put, select } from "redux-saga/effects"
import * as types from "../types"
import { GET, POST } from '../../service/api'
import { HeaderNonToken } from '../../service/header'
// import Config from '../../service/config'
// import Endpoint from '../../service/endpoint'

// const getStateUltimate = state => state.Ultimate

// https://apieinvitation.novicatania.com

export function* fetchWish() {
    try {
        // let stateUltimate = yield select(getStateUltimate)
        yield put({ type: types.ULTIMATE_SET_LOADER, value: true })
        // let link = "https://einvitation-weboosh.herokuapp.com/wishes/1"
        // let link = "https://api.einvitation.weboosh.id/wishes/1"
        let link = "https://apieinvitation.novicatania.com/wishes/1"
        const response = yield call(GET, link, { headers: HeaderNonToken() })
        if(response.length > 0){
            response.sort(function (a, b) {
                let keyB = parseInt(a.id);
                let keyA = parseInt(b.id);
                // Compare the 2 dates
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });
            yield put({ type: types.ULTIMATE_GET_WISH_SUCCESS, property: "wishesList", value: response })
        }
        
        yield put({ type: types.ULTIMATE_SET_LOADER, value: false })
    } catch (err) {
        console.log("saga > ", err);
    }
}

export function* postWish(data) {
    try {
        // let stateUltimate = yield select(getStateUltimate)
        yield put({ type: types.ULTIMATE_SET_LOADER, value: true })
        // let link = "https://einvitation-weboosh.herokuapp.com/wishes"
        // let link = "https://api.einvitation.weboosh.id/wishes"
        let link = "https://apieinvitation.novicatania.com/wishes"
        let body = {
            couple_id: "1",
            name: data.data.name,
            wishes: data.data.wishes
        }
        const response = yield call(POST, link, body, { headers: HeaderNonToken() })
        if (response.toLowerCase() === "success"){
            yield call(fetchWish)
        }
        yield put({ type: types.ULTIMATE_SET_LOADER, value: false })
    } catch (err) {
        console.log("saga > ", err);
    }
}

export function* fetchWishClient(id) {
    try {
        // let stateUltimate = yield select(getStateUltimate)
        yield put({ type: types.ULTIMATE_SET_LOADER, value: true })
        // let link = `https://einvitation-weboosh.herokuapp.com/wishes/${id.id}`
        // let link = `https://apitestproject.weboosh.id/wishes/${id.id}`
        // let link = `https://api.einvitation.weboosh.id/wishes/${id.id}`
        let link = `https://apieinvitation.novicatania.com/wishes/${id.id}`
        
        const response = yield call(GET, link, { headers: HeaderNonToken() })
        
        if (response.message.toLowerCase() === 'success.') {
            response.wish.sort((a, b) => {
                let keyB = parseInt(a.id);
                let keyA = parseInt(b.id);
                // Compare the 2 dates
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            })
            
            yield put({ type: types.ULTIMATE_GET_WISH_SUCCESS, property: "wishesList", value: response.wish })
        }

        yield put({ type: types.ULTIMATE_SET_LOADER, value: false })
    } catch (err) {
        console.log("saga > ", err);
    }
}

export function* postWishClient(datas) {
    try {
        yield put({ type: types.ULTIMATE_SET_LOADER, value: true })
        // let link = "https://einvitation-weboosh.herokuapp.com/wishes"
        // let link = `https://api.einvitation.weboosh.id/wishes`
        let link = `https://apieinvitation.novicatania.com/wishes`
        let body = {
            coupleId: datas.data.id,
            name: datas.data.name,
            wishes: datas.data.wishes,
            // isAttend: datas.data.isAttend,
            isAttendText: datas.data.isAttend,
            totalPerson: datas.data.totalPerson,
        }
        const response = yield call(POST, link, body, { headers: HeaderNonToken() })
        
        if (response.message.toLowerCase() === "success.") {
            let data = {
                id: datas.data.id
            }
            yield call(fetchWishesByID, data)
        }
        yield put({ type: types.ULTIMATE_SET_LOADER, value: false })
    } catch (err) {
        console.log("saga > ", err);
    }
}

export function* fetchWishesByID(id) {
    try {
        // let stateUltimate = yield select(getStateUltimate)
        yield put({ type: types.ULTIMATE_SET_LOADER, value: true })
        // let link = "https://einvitation-weboosh.herokuapp.com/wishes"
        // let link = `https://api.einvitation.weboosh.id/wishes/couple/${id.id}`
        let link = `https://apieinvitation.novicatania.com/wishes/couple/${id.id}`
        const response = yield call(GET, link, { headers: HeaderNonToken() })

        if (response.message.toLowerCase() === 'success.') {
            response.wish.sort((a, b) => {
                let keyB = parseInt(a.id);
                let keyA = parseInt(b.id);
                // Compare the 2 dates
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            })
            yield put({ type: types.ULTIMATE_GET_WISH_BY_ID_SUCCESS, property: "wishesList", value: response.wish })
        }
        
        yield put({ type: types.ULTIMATE_SET_LOADER, value: false })
    } catch (err) {
        console.log("saga > ", err);
    }
}


export default function* rootSaga() {
    yield all([
        takeLatest(types.ULTIMATE_GET_WISH, fetchWish),
        takeLatest(types.ULTIMATE_POST_WISH, postWish),

        takeLatest(types.ULTIMATE_GET_WISH_CLIENT, fetchWishClient),
        takeLatest(types.ULTIMATE_POST_WISH_CLIENT, postWishClient),
        takeLatest(types.ULTIMATE_GET_WISH_BY_ID, fetchWishesByID),
    ])
}