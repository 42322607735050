import React from 'react'
import { Fade, Zoom } from 'react-reveal';
import HeadShake from 'react-reveal/HeadShake';

//How to Use Component
{/* 
    <Thankyou
        name={name}
        illustration={imgUrl}
    /> 
*/}

const ThankyouSimple = (props) => {
    const { name, illustration } = props
    return (
        <div className="container-thankyou">
            <HeadShake>
                <div className="container-text-thankyou txt-size-2rem font-a-b">Thank You</div>
            </HeadShake>
            <Zoom>
                <div className="container-text-thankyou font-ar txt-size-35rem" style={{ lineHeight: '70px' }}>
                    {name}
                </div>
            </Zoom>
            <div className="container-image-thankyou">
                {/* <img
                    className="image-thankyou"
                    src={illustration}
                    loading="lazy"
                    alt="einvitation"
                /> */}
            </div>
        </div>
    )
}

export default ThankyouSimple
