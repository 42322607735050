import * as types from "../types"

const initState = {
    isLoading: false,
    attendeList: [],
    isModalOpen: false,
    isModalConfirmationOpen: false,
    source:{
        noPeserta: [],
        username: [],
        nama: [],
        noMeja: [],
        menu1: [],
        menu2: [],
        menu3: []
    },
    attendeData: {
        id: "",
        name: "",
        attandeeNumber: "",
        tableNumber: "",
        menu1: "",
        menu2: "!",
        menu3: "",
        menu4: ""
    }
}

export default function (state = initState, action) {
    switch (action.type) {
        case types.ACROPOLIS_GET_ATTENDE_LIST_SUCCESS:{
            return{
                ...state,
                [action.property]: action.value
            }
        }
        case types.ACROPOLIS_HANDLE_GLOBAL_STATE:{
            return {
                ...state,
                [action.property]: action.value
            }
        }
        case types.ACROPOLIS_HANDLE_SOURCE: {
            return {
                ...state,
                source:{
                    ...state.source,
                    [action.property]: action.value
                }
            }
        }
        default: {
            return { ...state }
        }
    }
}