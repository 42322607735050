import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../redux/Acropolis/action'
// import Countdown from "../../Countdown";

import Meta from "../../Meta";
import { Input, Select, Modal, notification } from 'antd';
import axios from "axios";
import { HeaderNonToken } from '../../../service/header';
import Loading from "../../Loading";

import {data, optionMainCourse, optionDessert, optionDrink, optionLiquor, acropolisCompanyId} from "./constant";

const Acropolis = (props) => {
    const { Acropolis, registerAttendance, handleStateGlobal } = props;

    const threeDaysInMs = 3 * 24 * 60 * 60 * 1000;
    const nowInMs = new Date().getTime();
    const targetDate = nowInMs + threeDaysInMs;
    const [body, setBody] = useState({});

    const handleOnChange = (name, value) => {
        if (name) {
            setBody({
                ...body,
                companyId: acropolisCompanyId,
                [name]: value,
            });
        }
    }

    const [api, contextHolder] = notification.useNotification();
    const openNotification = (title, message) => {
        api.info({
            message: title,
            description: message,
            placementL: "topRight",
        });
    };

    const onShowConfirmation = () => {
        if (Object.keys(body).length >= 5) {
            handleStateGlobal('isModalConfirmationOpen', true)
        } else {
            openNotification("error", 'Please Fill Your Data First');
        }
    }
    const onSubmit = (e) => {
        handleStateGlobal('isModalConfirmationOpen', false)
        const check404 = Object.keys(body).findIndex(item => body[item] === "404");
        const checkEmpty = Object.keys(body).findIndex(item => body[item] === "");

        if (check404 >= 0 || checkEmpty >= 0) {
            openNotification("error", 'Please Fill Your option First');
        } else if (Object.keys(body).length >= 5) {
            registerAttendance(body);
        } else {
            openNotification("error", 'Please Fill Your Data First');
        }
    }

    return <>
        <Meta
            metaTitle="Acropolis"
            description="Acropolis Multisarana International"
            keywords="Acropolis Multisarana International"
            image=""
            url=""
        />
        {contextHolder}
        {Acropolis.isLoading && <Loading />}

        <div id="company-invitation-container" style={{ background: data.firstColor, color: data.secondColor}}>
            {/*<img src={header} alt="header" />
            <div className="header-transition-wrapper"/>
            <div className="title-wrapper">
                <img src="/acropolis_asset/logo.png" alt="img-logo"/>
                <h1 className="font-StylishCalligraphyDemo event-for" style={{ color: data.thirdColor }}>{data.eventTitle2}</h1>
            </div>*/}
            <div className="title-wrapper">
                <img src="/acropolis_asset/banner.png" alt="img-banner"/>
            </div>

            <div className="event-information-wrapper position-relative icon-wrapper">
                {/* <img className="icon top-left" src="/acropolis_asset/party.png" alt="icon" /> */}

                <img className="frame-top" src="/acropolis_asset/frame-top.png" alt="meteor" />

                <img className="meteor-img" src="/acropolis_asset/planet.png" alt="meteor" />

                <div className="event-date-wrapper" style={{background: data.firstColor}}>
                    <h3 className="neon-text dark-brown" style={{ color: data.secondColor }}>
                        CATAT TANGGAL
                    </h3>
                    <div className="flex-row items-center justify-center">
                        <h5 className="m-0 neon-text">
                            {data.eventDate}
                        </h5>
                    </div>
                </div>

                <div className="dresscode-wrapper" style={{background: data.firstColor}}>
                    <h3 className="neon-text dark-brown" style={{ color: data.secondColor }}>
                        ATURAN BERBUSANA
                    </h3>
                    <h5 className="m-0 neon-text">
                        {data.eventDressCode}
                    </h5>
                </div>

                <div className="information-wrapper" style={{background: data.firstColor}}>
                    <h3 className="neon-text dark-brown" style={{ color: data.secondColor }}>
                        ACARA DISELENGGARAKAN DI
                    </h3>
                    <h5 className="m-0 neon-text">
                        {data.eventLocation}
                    </h5>
                </div>

                <div className="information-wrapper" style={{background: data.firstColor}}>
                    <h3 className="neon-text dark-brown" style={{ color: data.secondColor }}>
                        PERLU DIPERSIAPKAN
                    </h3>
                    <h6 className='neon-text mb-15' style={{fontSize: 'smaller'}}>
                        Setiap host diharapkan membawa powerbank dan modem, untuk keperluan live saat acara
                    </h6>
                    <div className="flex-row items-center justify-space-evenly">
                        {
                            data.prepare.map((item, idx) => {
                                return <div key={idx}>
                                    <img className="img-no-need" src={item.asset} alt={idx} key={idx} />
                                    <h5 className="m-0 neon-text fs-14">
                                        {item.name}
                                    </h5>
                                </div>
                            })
                        }
                    </div>
                </div>

                <div className="information-wrapper" style={{background: data.firstColor}}>
                    <div className="flex-row items-center justify-space-between">
                        {
                            data.noNeed.map((item, idx) => {
                                return <img className="img-no-need" src={item} alt={idx} key={idx} />
                            })
                        }
                    </div>
                    <h3 className="neon-text dark-brown mt-20" style={{ color: data.secondColor }}>
                        Dilarang membawa makanan/minuman dari luar.
                    </h3>
                </div>

                <img className="frame-btm" src="/acropolis_asset/frame-btm.png" alt="meteor" />

                <div className='information-wrapper'>
                    <div className="flex-row items-center justify-space-evenly">
                        <img className="img-no-need img-tiktok" src="/acropolis_asset/tikok_universe.png" alt={"Tiktok Universe"}/>
                        <img className="img-arrow" src="/acropolis_asset/arrow.png" alt="arrow"/>
                        <h5>
                            <span className="logo-tiktok">
                                <img
                                    src="/acropolis_asset/tiktok-logo.png"
                                    alt="tiktok-logo"
                                />
                            </span>
                            <a className="neon-text" href={data.tiktokLink} target="_blank">
                                {data.tiktokUsername}
                            </a>
                        </h5>
                    </div>
                </div>

                <div className="additional-information-wrapper" >
                    <h5 className="neon-text" style={{ color: "white" }}>{data.additionalInformation}</h5>
                </div>
            </div>

            <a className="more-information-wrapper position-relative text-center" href={data.websiteLink} target="_blank">
                <h3 className="neon-text dark-brown word-spacing-2" style={{ color: data.secondColor }}>
                    INFORMASI LEBIH LANJUT
                </h3>
                <img src="/acropolis_asset/more-information-3.png" alt="more-info"/>
            </a>

            <div className="form-wrapper position-relative">
                    <h3 className="title neon-text dark-brown mb-50" style={{ color: data.secondColor }}>
                        PENDAFTARAN
                    </h3>
                    <div className="mb-20">
                        <h6 className="neon-text dark-brown" style={{ color: data.secondColor }}>Username TikTok</h6>
                        <Input
                            name="username"
                            maxLength={50}
                            placeholder="Menggunakan @ cth: @acropolis.agency"
                            onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                        />
                    </div>
                    <div className="mb-20">
                        <h6 className="neon-text dark-brown" style={{ color: data.secondColor }}>Nama Lengkap</h6>
                        <Input
                            name="name"
                            maxLength={50}
                            placeholder="Masukkan nama lengkap"
                            onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                        />
                    </div>
                    <div className="mb-20">
                        <h6 className="neon-text dark-brown" style={{ color: data.secondColor }}>Main Course</h6>
                        <Select
                            name="mainCourse"
                            defaultValue="404"
                            style={{ width: "100%"}}
                            onChange={(value) => handleOnChange("menu1", value)}
                            options={optionMainCourse}
                        />
                    </div>
                    <div className="mb-20">
                        <h6 className="neon-text dark-brown" style={{ color: data.secondColor }}>Dessert</h6>
                        <Select
                            name="dessert"
                            defaultValue="404"
                            style={{ width: "100%"}}
                            onChange={(value) => handleOnChange("menu2", value)}
                            options={optionDessert}
                        />
                    </div>
                    <div className="mb-20">
                        <h6 className="neon-text dark-brown" style={{ color: data.secondColor }}>Drink</h6>
                        <Select
                            name="drink"
                            defaultValue="404"
                            style={{ width: "100%"}}
                            onChange={(value) => handleOnChange("menu3", value)}
                            options={optionDrink}
                        />
                    </div>
                    {/* <div className="mb-20">
                        <h6 className="neon-text dark-brown" style={{ color: data.secondColor }}>Liquor</h6>
                        <Select
                            name="liquor"
                            defaultValue="1"
                            style={{ width: "100%"}}
                            onChange={(value) => handleOnChange("menu4", value)}
                            options={optionLiquor}
                        />
                    </div> */}
                <button className="button-company" onClick={() => onShowConfirmation()}>Register</button>
            </div>
            <img src="/acropolis_asset/footer.png" alt="footer" />
            <Modal
                title=""
                visible={Acropolis.isModalOpen}
                onOk={() => handleStateGlobal('isModalOpen', false)}
                onCancel={() => handleStateGlobal('isModalOpen', false)}
                footer={[
                    <button className="button-company" onClick={() => handleStateGlobal('isModalOpen', false)}>Ok</button>
                ]}
            >
                <div className="mb-10">
                    <h3 style={{ color: data.secondColor }} className="font-cristik">
                        Simpan data berikut. Sebagai bukti pendaftaran dan doorprize
                    </h3>
                </div>
                <div className="flex-row justify-space-between items-center mb-10">
                    <p>Nomor Peserta:</p>
                    <p>{Acropolis.attendeData.attandeeNumber}</p>
                </div>
                <div className="flex-row justify-space-between items-center mb-10">
                    <p>Nomor Meja:</p>
                    <p>{Acropolis.attendeData.tableNumber}</p>
                </div>
            </Modal>
            <Modal
                visible={Acropolis.isModalConfirmationOpen}
                onOk={() => onSubmit()}
                onCancel={() => handleStateGlobal('isModalConfirmationOpen', false)}
                footer={[]}
            >
                <div className="mb-10">
                    <h3 style={{ color: data.secondColor }} className="font-cristik">
                        Apakah Anda yakin?
                    </h3>
                    <p>Semua perubahan tidak dapat dibatalkan</p>
                </div>
                <button className="button-company" onClick={() => onSubmit()}>Saya yakin</button>
            </Modal>
            {/*<div className="date-wrapper" style={{ background: data.firstColor }}>
                <div className="container-date-countdown">
                    <Countdown
                        type="days"
                        usingText
                        targetDate={targetDate}
                        textColor={data.secondColor}
                        onEnded={() => {console.log("a")}}
                    />
                </div>
            </div>*/}
        </div>
    </>
}

const mapStateToProps = (state) => ({
    Acropolis: state.Acropolis
})
const mapDispatchToProps = {
    ...actions
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Acropolis)
