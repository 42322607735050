import React from 'react'
import { Fade, Zoom } from 'react-reveal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt, faMap, faPaperPlane, faMusic, faVolumeMute } from '@fortawesome/free-solid-svg-icons'

//How to use Component
{/* 
    <Wishes
        name={"string"}
        setName={setName}
        wishes={"string"}
        setWishes={setWishes}
        wishesList={wishesList}
        isLoading={isLoading}
    /> 
*/}

const WishesSimple = (props) => {
    const {name, setName, wishes, setWishes, wishesList, isLoading, onSubmit} = props

    return (
        <div className="container-wishes">
            <Zoom>
                <p className="txt-size-2rem font-a-b">Wedding Wish</p>
                <form onSubmit={(e) => onSubmit(e)}>
                    <input placeholder="Name" value={name} type="text" maxLength="50" required onChange={(e) => setName(e.target.value)} />
                    <textarea placeholder="Wedding Wish" value={wishes} type="text" maxLength="500" rows="4" required onChange={(e) => setWishes(e.target.value)} />
                    <div className="add-wishes">
                        <button className="btn-wishes-style txt-size-1rem">
                            {
                                isLoading ? "Sending..." : "SEND WISH"
                            }
                        </button>
                    </div>
                </form>
            </Zoom>
            <br /><br />
            <Zoom>
                {
                    wishesList.length > 0 ? 
                        <div className="container-wishes-list">
                            {
                                wishesList.map((data, index) => (
                                    <div key={index}>
                                        <Fade>
                                            <div>
                                                <FontAwesomeIcon icon={faPaperPlane} />
                                            </div>
                                            <div className="content">
                                                <p className="name font-m-b txt-size-075rem">{data.name}</p>
                                                <p className="font-m-r txt-size-075rem">{data.wishes}</p>
                                            </div>
                                        </Fade>
                                    </div>
                                ))
                            }
                        </div>  
                    : <div></div>
                }
            </Zoom>
        </div>
    )
}

export default WishesSimple
