import React, { useState, useEffect } from "react";
import axios from "axios";
import { connect } from 'react-redux';
import * as actions from '../../../redux/Acropolis/action'
import { HeaderNonToken } from '../../../service/header';

import { Input, Modal, Table, notification } from 'antd';
import { forEach } from "lodash";
import Loading from "../../Loading";

const AcropolisAdmin = (props) => {
    const { Acropolis, fetchAttandee } = props;
    let { attendeList, source } = Acropolis;
    const [loading, setLoading] = useState(true);

    const columns = [
        {
            title: 'Nomor Peserta',
            dataIndex: 'nomorPeserta',
            filters: source.noPeserta,
            filterSearch: true,
            onFilter: (value, record) => record.nomorPeserta.startsWith(value),
            sorter: (a, b) => parseInt(a.nomorPeserta) - parseInt(b.nomorPeserta)//a.nomorPeserta.localeCompare(b.nomorPeserta),
        },
        {
            title: 'Username',
            dataIndex: 'username',
            filters: source.username,
            filterSearch: true,
            onFilter: (value, record) => record.username.startsWith(value),
            sorter: (a, b) => a.username.localeCompare(b.username),
        },
        {
            title: 'Nama',
            dataIndex: 'nama',
            filters: source.nama,
            filterSearch: true,
            onFilter: (value, record) => record.nama.startsWith(value),
            sorter: (a, b) => a.nama.localeCompare(b.nama),
        },
        {
            title: 'Nomor Meja',
            dataIndex: 'nomorMeja',
            filters: source.noMeja,
            filterSearch: true,
            onFilter: (value, record) => record.nomorMeja.startsWith(value),
            sorter: (a, b) => a.nomorMeja.localeCompare(b.nomorMeja),
        },
        {
            title: 'Main Course',
            dataIndex: 'menu1',
            filters: source.menu1,
            filterSearch: true,
            onFilter: (value, record) => record.menu1.startsWith(value),
            sorter: (a, b) => a.menu1.localeCompare(b.menu1),
        },
        {
            title: 'Dessert',
            dataIndex: 'menu2',
            filters: source.menu2,
            filterSearch: true,
            onFilter: (value, record) => record.menu2.startsWith(value),
            sorter: (a, b) => a.menu2.localeCompare(b.menu2),
        },
        {
            title: 'Drink',
            dataIndex: 'menu3',
            filters: source.menu3,
            filterSearch: true,
            onFilter: (value, record) => record.menu3.startsWith(value),
            sorter: (a, b) => a.menu3.localeCompare(b.menu3),
        },
    ]

    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };

    const data = {
        firstColor: "#24094C",
        secondColor: "#ef9c63",
        thirdColor: "#48abe0",
        fourthColor: "#d11ca4",
        eventTitle: "Acropolis Agency",
        eventTitle2: "1st Anniversary",
        eventDescription: ["OSHIEN ZUKY | ALKA FLOW | NADHYA CELLO |", "LIA CHRIS | RONALD 3D | MARSHA | AND MANY MORE"],
        eventDate: "18 January 2023",
        eventDays: "Monday at 10.00 AM",
        eventDressCode: "Blue & White (Sporty Casual)",
        eventLocation: "BAR & RESTO SERPONG",
        additionalInformation: "DOORS OPEN At 12.00PM"
    }

    const [api, contextHolder] = notification.useNotification();
    const openNotification = (title, message) => {
        api.info({
            message: title,
            description: message,
            placementL: "topRight",
        });
    };

    useEffect(() => {
        // Component Did Mount
        // Call API
        fetchAttandee();
        setLoading(false);
    }, [])

    return <div id="company-invitation-container" className="company-invitation-container type-admin" style={{ background: data.firstColor, color: data.secondColor}}>
        {loading && <Loading />}

        {contextHolder}
        <div className="company-admin-invitation-wrapper">
            <div className="mb-20">
                <h4 style={{ color: data.secondColor }}>List Attendance</h4>
            </div>
        </div>

        <Table columns={columns} dataSource={attendeList} onChange={onChange} />
    </div>
}

const mapStateToProps = (state) => ({
    Acropolis: state.Acropolis
})
const mapDispatchToProps = {
    ...actions
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AcropolisAdmin);