import React from 'react';
import _ from "lodash";

const FullImage = (props) => {
    const { data } = props;

    return <div id="full-image-component" className={_.get(data, "componentClassName", "")}>
        <img className="img" src={_.get(data, "photo", "")} alt="full-image" />
    </div>
}

export default FullImage;