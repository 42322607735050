import { all, takeLatest, call, put, select } from "redux-saga/effects"
import { messages } from "../../components/messageBox"
import * as types from "../types"
import { GET, POST } from '../../service/api'
import { HeaderNonToken } from '../../service/header'
import {acropolisCompanyId} from "../../components/page/acropolis/constant";
import {isContainCharAndConcat} from "../../helpers/text";

export function* fetchAttandee() {
    try {
        // yield put({ type: types.ULTIMATE_SET_LOADER, value: true })
        let link = `https://einvitation-weboosh.herokuapp.com/attandee/${acropolisCompanyId}`
        const res = yield call(GET, link, { headers: HeaderNonToken() })
        let dataListAttande = res.attandeeEntity.map((obj) => ({
            ...obj,
            key: obj.id,
            nomorPeserta: obj.attandeeNumber,
            username: obj.username,
            nama: obj.name,
            nomorMeja: obj.tableNumber,
            menu1: obj.menu1 ? obj.menu1 : '-', //Main Course
            menu2: obj.menu2 ? obj.menu2 : '-', //Dessert
            menu3: obj.menu3 ? obj.menu3 : '-', //Drink
        }))

        let dataUsername = dataListAttande.map((obj) => ({
            text: obj.username,
            value: obj.username
        })).filter((value, index, self) =>
            //remove duplicate arrayo= of obj 
            index === self.findIndex((t) => (
                t.value === value.value
            ))
        )

        let dataNoPeserta = res.attandeeEntity.map((obj) => ({
            text: obj.attandeeNumber,
            value: obj.attandeeNumber
        })).filter((value, index, self) =>
            //remove duplicate arrayo= of obj 
            index === self.findIndex((t) => (
                t.value === value.value
            ))
        )

        let dataNama = res.attandeeEntity.map((obj) => ({
            text: obj.name,
            value: obj.name
        })).filter((value, index, self) =>
            //remove duplicate arrayo= of obj 
            index === self.findIndex((t) => (
                t.value === value.value
            ))
        )

        let dataNoMeja = res.attandeeEntity.map((obj) => ({
            text: obj.tableNumber,
            value: obj.tableNumber
        })).filter((value, index, self) =>
            //remove duplicate arrayo= of obj 
            index === self.findIndex((t) => (
                t.value === value.value
            ))
        )

        let dataMenu1 = res.attandeeEntity.map((obj) => ({
            text: obj.menu1 ? obj.menu1 : '-',
            value: obj.menu1 ? obj.menu1 : '-'
        })).filter((value, index, self) =>
            //remove duplicate arrayo= of obj 
            index === self.findIndex((t) => (
                t.value === value.value
            ))
        )

        let dataMenu2 = res.attandeeEntity.map((obj) => ({
            text: obj.menu2 ? obj.menu2 : '-',
            value: obj.menu2 ? obj.menu2 : '-'
        })).filter((value, index, self) =>
            //remove duplicate arrayo= of obj 
            index === self.findIndex((t) => (
                t.value === value.value
            ))
        )

        let dataMenu3 = res.attandeeEntity.map((obj) => ({
            text: obj.menu3 ? obj.menu3 : '-',
            value: obj.menu3 ? obj.menu3 : '-'
        })).filter((value, index, self) =>
            //remove duplicate arrayo= of obj 
            index === self.findIndex((t) => (
                t.value === value.value
            ))
        )

        yield put({ type: types.ACROPOLIS_GET_ATTENDE_LIST_SUCCESS, property: "attendeList", value: dataListAttande })
        yield put({ type: types.ACROPOLIS_HANDLE_SOURCE, property: "noPeserta", value: dataNoPeserta })
        yield put({ type: types.ACROPOLIS_HANDLE_SOURCE, property: "username", value: dataUsername })
        yield put({ type: types.ACROPOLIS_HANDLE_SOURCE, property: "nama", value: dataNama })
        yield put({ type: types.ACROPOLIS_HANDLE_SOURCE, property: "noMeja", value: dataNoMeja })
        yield put({ type: types.ACROPOLIS_HANDLE_SOURCE, property: "menu1", value: dataMenu1 })
        yield put({ type: types.ACROPOLIS_HANDLE_SOURCE, property: "menu2", value: dataMenu2 })
        yield put({ type: types.ACROPOLIS_HANDLE_SOURCE, property: "menu3", value: dataMenu3 })

        // yield put({ type: types.ULTIMATE_SET_LOADER, value: false })
    } catch (err) {
        console.log("saga > ", err);
    }
}

export function* fetchRegister(param) {
    try {
        yield put({ type: types.ACROPOLIS_HANDLE_GLOBAL_STATE, property: 'isLoading', value: true })
        let link = "https://einvitation-weboosh.herokuapp.com/attandee";
        let body = {
            ...param.dataAction,
            username: isContainCharAndConcat(param.dataAction.username, "@"),
        }
        const res = yield call(POST, link, body, { headers: HeaderNonToken() })

        if (res.status.toLowerCase() === "failed") {
            if (res.reason === "Username Not Recognize") {
                messages("Error", 'Username Anda tidak terdaftar sebagai tamu silahkan cek kembali', "info", false);
            } else if (res.reason) {
                messages("Error", res.reason, "info", false);
            } else {
                messages("Error", 'Terjadi kesalahan, silahkan coba beberapa saat lagi', "info", false);
            }
        } else if (res.status.toLowerCase() === "success") {
            yield put({ type: types.ACROPOLIS_HANDLE_GLOBAL_STATE, property: 'attendeData', value: res.attandeeEntity[0] })
            yield put({ type: types.ACROPOLIS_HANDLE_GLOBAL_STATE, property: 'isModalOpen', value: true })
            // messages("Info", 'Yuhuu success', "info", true);
        }
        yield put({ type: types.ACROPOLIS_HANDLE_GLOBAL_STATE, property: 'isLoading', value: false })
    } catch (err) {
        yield put({ type: types.ACROPOLIS_HANDLE_GLOBAL_STATE, property: 'isLoading', value: false })
        messages("Error", 'Oops! Ada kesalahan, silahkan coba beberapa saat lagi', "error", false);
    }
}

export default function* rootSaga() {
    yield all([
        takeLatest(types.ACROPOLIS_GET_ATTENDE_LIST, fetchAttandee),
        takeLatest(types.ACROPOLIS_REGISTER_ATTENDANCE, fetchRegister),
    ])
}

// axios({
//     url: "https://einvitation-weboosh.herokuapp.com/attandee",
//     method: "POST",
//     headers: HeaderNonToken(),
//     data: body,
// })
//     .then((res) => {
//         if (res) {
//             if (res.status === "FAILED") {
//                 if (res.reason === "Username Not Recognize") {
//                     openNotification("error", 'Username Anda tidak terdaftar sebagai tamu silahkan cek kembali');
//                 } else if (res.reason) {
//                     openNotification("error", res.reason);
//                 } else {
//                     openNotification("error", 'Terjadi kesalahan silahkan coba beberapa saat lagi');
//                 }
//             } else if (res.status === "Success") {
//                 setResponse(res);
//                 setIsModalOpen(true);
//                 setLoading(false);
//             }
//         }

//     })
//     .catch((err) => {
//         console.error("error".error);
//         openNotification("error", 'Terjadi kesalahan silahkan coba beberapa saat lagi');
//     });