import React, { Fragment } from 'react'
import { Fade, Zoom } from 'react-reveal';
import { faCalendarAlt, faMap, faPaperPlane, faMusic, faVolumeMute } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

{/* How to Use Component
    <Event
        data={data}
        fullDate={"string"}
        time={"string"}
        holyMatrimony={"String"}
        holyMatrimonyTime={"string"}
        holyMatrimonyDetails={"String"}
        holyMatrimonyDate={}
        reception={"String"}
        receptionTime={"string"}
        receptionDetails={"String"}
        calendar={"String"}
    /> 
*/}

const EventSimple = (props) => {
    const {
        fullDate, 
        time, 
        holyMatrimony, 
        holyMatrimonyTime, 
        holyMatrimonyDetails1, 
        holyMatrimonyDetails2,
        holyMatrimonyDetails3,
        holyMatrimonyDetails4,
        reception, 
        receptionTime, 
        receptionDetails, 
        calendar
    } = props
    return (
        <div className="container-event">
            <Zoom> 
                <p className="txt-size-2rem font-a-b">Save The Date</p>
                <div className="content-event">
                    <p className="font-m-b">The Wedding Day</p>
                    <FontAwesomeIcon icon={faCalendarAlt} />
                    <p className="font-m-r">{fullDate}</p>
                    <div className="line"></div><p className="font-m-r">{time}</p>
                    <br/>
                    <FontAwesomeIcon icon={faMap} />
                    <p className="font-m-b">Holy Matrimony</p>
                    <p className="font-m-r">{reception}</p>
                    <p className="font-m-r">{receptionTime}</p>
                    <p className="font-m-r">{receptionDetails}</p>
                    <p className="font-m-r">Live Streaming 1: <a href={holyMatrimonyDetails1} target="_blank" rel="noopener noreferrer" className="font-m-m">Click Here</a></p>
                    <p className="font-m-r">Live Streaming 2: <a href={holyMatrimonyDetails2} target="_blank" rel="noopener noreferrer" className="font-m-m">Click Here</a></p>
                    <p className="font-m-r">Live Streaming 3: <a href={holyMatrimonyDetails3} target="_blank" rel="noopener noreferrer" className="font-m-m">Click Here</a></p>
                    <p className="font-m-r">Live Streaming 4: <a href={holyMatrimonyDetails4} target="_blank" rel="noopener noreferrer" className="font-m-m">Click Here</a></p>
                </div>
                <br /><br />
                <div className="add-google">
                    <a target="_blank" rel="noopener noreferrer" href={calendar}>
                        ADD TO GOOGLE CALENDAR
                    </a>
                </div>
            </Zoom>
        </div>
    )
}

export default EventSimple
