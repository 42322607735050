import React, { lazy, useEffect, useState, Suspense, useRef } from 'react'
import { connect } from 'react-redux';
import * as actions from '../../../redux/Ultimate/action.js'

import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

import './style.scss'

import dataCouple from '../../../data/cindy.json'

import { Table, Col, Row } from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt, faMap, faPaperPlane, faMusic, faVolumeMute } from '@fortawesome/free-solid-svg-icons'
import { HeartFilled, InstagramOutlined } from '@ant-design/icons'

const Ultimate = (props) => {
    const { Ultimate, fetchWishesByID, fetchWishesClient, postWishClient, fetchWishes, postWish } = props
    let { isLoading, wishesList } = Ultimate
    
    const { Column } = Table
    
    const [openInv, setOpenInv] = useState(false)
    const [audioSrc, setAudioSrc] = useState('')
    const [playing, setPlaying] = useState(false)
    
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
            width: '30%',
            key: 'name'
        },
        {
            title: 'Attendence',
            dataIndex: 'isAttendText',
            sorter: (a, b) => a.isAttendText.localeCompare(b.isAttendText),
            width: '30%',
            key: 'isAttendText',
                // render: (isAttend) => {
                //     let str = ''
                //     if (isAttend === 'yes1'){
                //         str = 'Yes, Holy Matrimony only'
                //     } else if (isAttend === 'yes1'){
                //         str = 'Yes, The Wedding Day only'
                //     } else if (isAttend === 'yes1'){
                //         str = 'Yes, I attend both'
                //     }else{
                //         str = 'No'
                //     }
                //     return(
                //         <span>
                //             {str}
                //         </span>
                //     )
                // },
        },
        {
            title: 'Total Person',
            dataIndex: 'totalPerson',
            sorter: (a, b) => a.totalPerson - b.totalPerson,
            key: 'totalPerson',
            width: '30%',
        }
    ]

    useEffect(() => {
        fetchWishesByID(dataCouple.couple.idJkt)
    }, [])

    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };

    const exportToExcel = () => {
        let data = wishesList.map((obj) => ({
            'Name': obj.name,
            'Attendence': obj.isAttendText,
            'Total Person': obj.totalPerson,
            'Wishes': obj.wishes
        })).sort((a, b) => a.Name.localeCompare(b.Name));
        
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Attendence");

        // Buffer to store the generated Excel file
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

        saveAs(blob, "cindy_kevin_jkt_attendence.xlsx");
    };

    return (
        <div>
            <Row>
                <Col>
                    <Table columns={columns} dataSource={wishesList} onChange={onChange} loading={isLoading}>
                        
                    </Table>
                </Col>
            </Row>


            {
                isLoading ? 
                    null 
                    : <Row justify="center">
                        <Col>
                            <button onClick={() => exportToExcel()}>
                                Download to Excel
                            </button>
                        </Col>
                    </Row>
            }
        </div>
    )
}

const mapStateToProps = (state) => ({
    Ultimate: state.Ultimate
})
const mapDispatchToProps = {
    ...actions
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Ultimate);
