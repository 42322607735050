import React, {Fragment} from 'react'
import { Fade, Zoom } from 'react-reveal';

// How to Use Component
{/* 
    <Quote
        imgSrc={"https://res.cloudinary.com/weboosh/image/upload/v1638888052/sample_b04oen.webp"}
        illustration={"img"}
        data={data}
    /> 
*/}

const QuoteSimple = (props) => {
    const { imgSrc, illustration, data } = props
    return (
        <Fragment>
            <div className="container-slider">
                <Fade>
                    <div className="container-slider-photo">
                        <img
                            className="slide-photo"
                            src={imgSrc}
                            loading="lazy"
                            alt="image einvitation"
                        />
                    </div>
                </Fade>
            </div>
            
            <div className="container-quotes">
                <div className="container-image-quotes">
                    {/* <img
                        className="image-quotes"
                        src={illustration}
                        loading="lazy"
                        alt="image einvitation"
                    /> */}
                </div>
                <Zoom>
                    <div className="container-text font-a txt-size-2rem" style={{ lineHeight: '30px' }}>
                        "{data.couple.quotation2}"
                    </div>
                    <div className="container-text font-a txt-size-2rem" style={{ lineHeight: '30px' }}>
                        {data.couple.quotation2desc}
                    </div>
                </Zoom>
            </div>
        </Fragment>
    )
}

export default QuoteSimple
