import React from 'react';
import _ from "lodash";
import {
    logoEinvitation
} from "../asset";
import {InstagramOutlined, WhatsAppOutlined} from "@ant-design/icons";

const FooterSecond = (props) => {
    const { data } = props;

    return <div id="footer-second-component" className={_.get(data, "componentClassName", "")}>
        <div className="body-wrapper">
            <img className="img" src={logoEinvitation} alt="logo"/>
            <div className="social-media-wrapper">
                <a className="item" href="https://www.instagram.com/weboosh">
                    <div className="row justify-content-center align-items-center" style={{ color: 'white', fontSize:'25px' }}>
                        <InstagramOutlined/>
                    </div>
                </a>
                <a className="item" href="https://api.whatsapp.com/send?phone=6289628576801">
                    <div className="row justify-content-center align-items-center" style={{ color: 'white', fontSize:'25px' }}>
                        <WhatsAppOutlined />
                    </div>
                </a>
            </div>
        </div>
    </div>
}

export default FooterSecond;